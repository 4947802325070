import { useDispatch } from 'react-redux';
import { setSelectedSpecialId } from '../../store/specials/specialsSlice';

const Special = ({ special, singleSpecialView }) => {
    const dispatch = useDispatch();

    return (
        <div
            className='headless-specials-card'
            style={{
                backgroundImage: `url(${
                    special?.menuDisplayConfiguration?.image !=
                    '/images/default-special-card.jpg'
                        ? special?.menuDisplayConfiguration?.image
                        : 'https://range-headless.s3.amazonaws.com/specials-bg.jpg'
                })`,
            }}
            onClick={() => {
                if (!singleSpecialView) {
                    dispatch(setSelectedSpecialId(special.id));
                }
            }}
        >
            <div className='headless-specials-card-overlay'></div>
            <div className='headless-specials-title'>{special.name}</div>
            <a role='button' className='headless-specials-button'>
                Shop Offer
            </a>
        </div>
    );
};

export default Special;
