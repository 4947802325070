import { useEffect } from '@wordpress/element';
import { shortcodeAttsHelper, getInitialMenuFilter } from '../util';
import CategoriesGrid from '../components/ProductsView/CategoriesGrid';
import SpecialsGrid from '../components/ProductsView/SpecialsGrid';
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerId } from '../store/retailer/retailerSlice';
import {
    fetchSpecialsList,
    selectSpecialsListStatus,
} from '../store/specials/specialsSlice';
import LoadingSpinner from '../components/LoadingSpinner';
import ProductsGrid from '../components/ProductsView/ProductsGrid';
import { menuFilter } from '../config';

const ProductsView = ({ data }) => {
    const { shortcodeAtts, shopEditor, retailerConfig, rootId, surfside } =
        data;

    const dispatch = useDispatch();
    const retailerId = useSelector(selectRetailerId);
    const specialsListStatus = useSelector(selectSpecialsListStatus);

    const atts = shortcodeAttsHelper(shortcodeAtts);
    const currentRetailerConfig = retailerConfig.retailers[retailerId];
    const filterTerms = {
        ...data.filterTerms,
        brands: data.filterTerms.brands[retailerId],
    };

    // console.log({ retailerConfig, shopEditor });

    /**
     * Set specials.
     * Runs only on initial pageload.
     */
    useEffect(() => {
        if (specialsListStatus === 'idle' && atts.specials) {
            dispatch(fetchSpecialsList(retailerId));
        }
    }, [specialsListStatus, dispatch]);

    return (
        <>
            {atts.menuFilter.category === 'ALL' && (
                <CategoriesGrid
                    atts={atts}
                    images={shopEditor.menuConfig.categoryImages}
                    categories={shopEditor.menuConfig.categoriesToShow}
                    menuUrl={currentRetailerConfig?.menuUrl}
                    root={rootId}
                />
            )}
            {atts.specials &&
                (specialsListStatus === 'succeeded' ? (
                    <SpecialsGrid
                        atts={atts}
                        menuUrl={currentRetailerConfig?.menuUrl}
                        styleOptions={shopEditor.customStyleOptions}
                        root={rootId}
                    />
                ) : (
                    <LoadingSpinner loadingType='slider-smaller' />
                ))}
            {atts.menuFilter.brandId && (
                <ProductsGrid
                    atts={atts}
                    filter={getInitialMenuFilter(
                        {
                            ...menuFilter,
                            brandId: atts.menuFilter.brandId,
                        },
                        filterTerms
                    )}
                    root={rootId}
                    styleOptions={shopEditor.customStyleOptions}
                    surfside={surfside}
                />
            )}
            {atts.menuFilter.category && atts.menuFilter.category !== 'ALL' ? (
                <ProductsGrid
                    atts={atts}
                    filter={getInitialMenuFilter(
                        {
                            ...menuFilter,
                            category: atts.menuFilter.category,
                        },
                        filterTerms
                    )}
                    root={rootId}
                    styleOptions={shopEditor.customStyleOptions}
                    surfside={surfside}
                />
            ) : null}
        </>
    );
};

export default ProductsView;
