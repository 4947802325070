import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { retailerQuery } from '../../api/api';

const initialState = {
    data: null,
    status: 'idle',
    error: null,
};

export const fetchRetailerData = createAsyncThunk(
    'retailer/fetchRetailerData',
    async (retailerId) => {
        const response = await retailerQuery(retailerId);
        return response;
    }
);

export const retailerSlice = createSlice({
    name: 'retailer',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchRetailerData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRetailerData.fulfilled, (state, action) => {
                if (action.payload.hasOwnProperty('errors')) {
                    state.status = 'failed';
                    state.data = false;
                    state.error = action.payload.errors;
                } else {
                    state.status = 'succeeded';
                    state.data = action.payload.data.retailer;
                }
            });
    },
});

export const selectRetailerData = (state) => state.retailer.data;
export const selectRetailerId = (state) => state.retailer.data.id;

export default retailerSlice.reducer;
