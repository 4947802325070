import { useState, useRef, useEffect } from '@wordpress/element';
import {
    getProducts,
    getSpecialsMenu,
    getStaffPicksMenu,
} from '../../api/methods';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';
import {
    selectSelectedSpecialId,
    selectSpecialsList,
} from '../../store/specials/specialsSlice';

const ShowMore = ({
    selectedFilters,
    productsToDisplay,
    setProductsToDisplay,
    paginationOffset,
    setPaginationOffset,
    paginationLimit,
    sort,
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadMoreButtonRef = useRef(null);

    const retailerId = useSelector(selectRetailerId);
    const pricingType = useSelector((state) => state.cart.data.pricingType);
    const selectedSpecialId = useSelector(selectSelectedSpecialId);
    const specialsList = useSelector(selectSpecialsList);

    const shouldNotLoadMore =
        paginationLimit * (paginationOffset - 1) > productsToDisplay.length ||
        paginationLimit > productsToDisplay.length;

    /**
     * Use scroll to update pagination offset, which loads more products.
     * @returns void
     */
    const handleScroll = async () => {
        const loadMoreButton = loadMoreButtonRef.current;
        if (!loadMoreButton || isFetching) return;

        const { top } = loadMoreButton.getBoundingClientRect();
        const isButtonVisible = top - window.innerHeight <= 100;

        if (isButtonVisible && !isLoading) {
            setPaginationOffset((prevOffset) => prevOffset + 1);
        }
    };

    let debounceTimer;
    /**
     * Debounce `handleScroll`. Attach to scroll listener.
     */
    const debounceScroll = () => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleScroll();
        }, 300);
    };

    /**
     * Load and display more products when pagination offset is updated.
     */
    useEffect(() => {
        const loadMore = async () => {
            if (shouldNotLoadMore) {
                return;
            }

            setIsFetching(true);
            setIsLoading(true);

            let newProducts;
            if (selectedFilters.staffpicks) {
                newProducts = await getStaffPicksMenu(
                    retailerId,
                    paginationLimit * paginationOffset,
                    paginationLimit,
                    'STAFF_PICKS',
                    pricingType,
                    sort
                );
            } else if (selectedFilters.specials) {
                if (selectedSpecialId) {
                    newProducts = await getSpecialsMenu(
                        retailerId,
                        paginationLimit * paginationOffset,
                        paginationLimit,
                        [selectedSpecialId],
                        pricingType,
                        sort
                    );
                } else {
                    newProducts = await getSpecialsMenu(
                        retailerId,
                        paginationLimit * paginationOffset,
                        paginationLimit,
                        specialsList.map((specials) => specials.id),
                        pricingType,
                        sort
                    );
                }
            } else {
                newProducts = await getProducts(
                    retailerId,
                    paginationLimit * paginationOffset,
                    paginationLimit,
                    selectedFilters,
                    pricingType,
                    sort
                );
            }

            await setProductsToDisplay([...productsToDisplay, ...newProducts]);

            setIsFetching(false);
            setIsLoading(false);
        };

        if (paginationOffset !== 1) {
            loadMore();
        }
    }, [paginationOffset]);

    /**
     * Attach (and remove) scroll event listener.
     */
    useEffect(() => {
        window.addEventListener('scroll', debounceScroll);

        return () => window.removeEventListener('scroll', debounceScroll);
    }, []);

    return (
        <>
            {isLoading && <LoadingSpinner />}
            {!shouldNotLoadMore && productsToDisplay?.length > 0 && (
                <button
                    ref={loadMoreButtonRef}
                    className='headless-button headless-button-small headless-load-more'
                    onClick={() => {
                        setPaginationOffset((prevOffset) => prevOffset + 1);
                    }}
                >
                    Load More
                </button>
            )}
        </>
    );
};

export default ShowMore;
