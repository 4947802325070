const ProductImage = ({ url, alt }) => {
    return (
        <div className='headless-sp-image-wrapper col-lg-6 col-xxl-5'>
            <div className='headless-sp-image sticky-top'>
                <img
                    srcSet={`${url}?w=500 500w, ${url}?w=800 800w, ${url}?w=2000 2000w`}
                    sizes={
                        '(max-width: 576px) 500px, (max-width: 1799px) 800px, (min-width: 1800px) 2000px'
                    }
                    src={`${url}?w=800`}
                    alt={alt}
                />
            </div>
        </div>
    );
};

export default ProductImage;
