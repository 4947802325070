import { useState, useEffect } from '@wordpress/element';
import groupBy from 'lodash.groupby';
import CartProduct from './CartProduct';
import { useSelector } from 'react-redux';
import { selectCartData } from '../../store/cart/cartSlice';

const CartProducts = () => {
    const cartData = useSelector(selectCartData);

    /**
     * Takes current items in cart and groups them together if they have the same `productId`.
     * Different variants of the same product type have their own item object in the cart.
     * This makes it easier to add variants to the same product in the cart UI.
     */
    const [groupedItems, setGroupedItems] = useState(
        groupBy(cartData?.items, (item) => item.productId)
    );

    useEffect(() => {
        setGroupedItems(groupBy(cartData?.items, (item) => item.productId));
    }, [cartData]);

    return (
        <>
            {Object.keys(groupedItems).map((productId, i) => (
                <CartProduct key={i} items={groupedItems[productId]} />
            ))}
        </>
    );
};

export default CartProducts;
