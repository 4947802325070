

const PotencyChart = ({ potencyThc, potencyCbd }) => {
    return (
        <>
            {potencyThc.formatted !== '' || potencyCbd.formatted !== '' ? (
                <div className='headless-sp-potency'>
                    <div className='headless-breadcrumb'>
                        Potency Chart
                    </div>

                    <div className='sp-potency-data'>
                        {potencyThc.range.length > 0 ? (
                            <div className='sp-potency-single'>
                                <div className='headless-breadcrumb sp-potency-name'>
                                    THC
                                </div>
                                <div className='potency-bar-number'>
                                    {potencyThc.formatted}
                                </div>
                                <div className='sp-potency-bar'>
                                    <div
                                        className='potency-bar-inner potency-bar-thc'
                                        style={{
                                            width: `${potencyThc.range[0]}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        ) : null}

                        {potencyCbd.range.length > 0 ? (
                            <div className='sp-potency-single'>
                                <div className='headless-breadcrumb sp-potency-name'>
                                    CBD
                                </div>
                                <div className='potency-bar-number'>
                                    {potencyCbd.formatted}
                                </div>
                                <div className='sp-potency-bar'>
                                    <div
                                        className='potency-bar-inner potency-bar-cbd'
                                        style={{
                                            width: `${potencyCbd.range[0]}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PotencyChart;
