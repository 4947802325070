import { filterTypes, menuFilter } from './config';

/**
 * Converts string boolean values to actual booleans.
 * Converts string number values to actual numbers.
 *
 * @param {object} atts Shortcode attributes.
 * @returns {object}
 */
export const shortcodeAttsHelper = (atts) =>
    Object.fromEntries(
        Object.entries(atts).map(([att, value]) => {
            if (value === 'true') {
                return [att, true];
            } else if (value === 'false') {
                return [att, false];
            } else if (typeof value === 'string' && !isNaN(value)) {
                return [att, Number(value)];
            } else {
                return [att, value];
            }
        })
    );

/**
 * Remove excess whitespace and special characters, convert to lowercase and replace spaces with dashes.
 * "This is a String!" -> "this-is-a-string"
 *
 * @param {string} str
 * @returns {string}
 */
export const toLowerWithDashes = (str) =>
    str
        .replace(/[\W_\s]+/g, ' ')
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase();

/**
 * Gets existing checkout info from localStorage, if it exists.
 * @returns {object|false}
 */
export const getExistingCheckout = () => {
    const checkout = window.localStorage.getItem('DUTCHIE_CHECKOUT');

    if (typeof checkout === 'string' && checkout !== 'undefined') {
        return JSON.parse(checkout);
    } else {
        return false;
    }
};

/**
 * Get initial menu filter object.
 *
 * @param {object} menuFilterAtts Shortcode filter atts.
 * @param {object} filterTerms Object containing arrays of filter terms.
 *
 * @returns {object}
 */
export const getInitialMenuFilter = (menuFilterAtts, filterTerms) => {
    const menuFilterClone = Object.assign({}, menuFilter);

    Object.entries(menuFilterAtts).forEach(([key, value]) => {
        if (key === filterTypes.specials && value) {
            menuFilterClone.specials = true;
        } else if (value) {
            if (value === 'ALL') {
                menuFilterClone[key] = '';
            } else {
                if (key === filterTypes.category) {
                    // ensure category value passed is a valid enum.
                    if (filterTerms.categories.includes(value)) {
                        menuFilterClone[key] = value;
                    } else {
                        console.error(
                            'Invalid value passed to shortcode category attribute.',
                            { value }
                        );
                        menuFilterClone[key] = '';
                    }
                } else {
                    menuFilterClone[key] = value;
                }
            }
        }
    });

    return menuFilterClone;
};

/**
 * Remove empty `menuFilter` items, as well as specials property.
 * Used before sending in a GQL query.
 *
 * @param {object} providedMenuFilter
 * @returns {object}
 */
export const cleanMenuFilterForQuery = (providedMenuFilter) => {
    const clone = Object.assign({}, providedMenuFilter);
    delete clone.specials;

    // only returns keys that are strings or arrays with one or more values, or potency.
    return Object.fromEntries(
        Object.entries(clone).filter(
            ([k, v]) =>
                v.length > 0 ||
                (k === 'potencyThc' && v.min !== menuFilter.potencyThc.min) ||
                (k === 'potencyThc' && v.max !== menuFilter.potencyThc.max) ||
                (k === 'potencyCbd' && v.min !== menuFilter.potencyCbd.min) ||
                (k === 'potencyCbd' && v.max !== menuFilter.potencyCbd.max)
        )
    );
};

/**
 * If no retailer data from shortcode, get stored id or return false to trigger modal.
 * Otherwise, return the provided id. If error, return stored id, then false to trigger modal.
 *
 * @param {object} retailer Retailer shortcode data.
 * @returns {string|false}
 */
export const getRetailerId = (retailer) => {
    if (!retailer) {
        return getExistingCheckout().retailerId || false;
    }

    return retailer?.providedId || getExistingCheckout().retailerId || false;
};

/**
 * Convert a string to uppercase words like in PHP ucwords()
 * @param {string} str
 * @returns {string}
 */
export const ucwords = (str) => {
    return str.toLowerCase().replace(/(^|\s)[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
};

/**
 * Get today's day e.g. 'Friday'.
 * @returns string
 */
export const today = () => {
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    return days[new Date().getDay()];
};

/**
 * Determines whether store is open or closed.
 * @see https://stackoverflow.com/a/26078713
 * @param {object} todaysHours retailerData hours object.
 * @returns bool
 */
export const isOpen = (todaysHours) => {
    if (!todaysHours.active) {
        return false;
    }

    let { start, end } = todaysHours;

    function dateObj(d) {
        var parts = d.split(/:|\s/),
            date = new Date();
        if (parts.pop().toLowerCase() == 'pm') parts[0] = +parts[0] + 12;
        date.setHours(+parts.shift());
        date.setMinutes(+parts.shift());
        return date;
    }

    var now = new Date();

    var startDate = dateObj(start); // get date objects
    var endDate = dateObj(end);

    if (startDate > endDate) {
        // check if start comes before end
        var temp = startDate; // if so, assume it's across midnight
        startDate = endDate; // and swap the dates
        endDate = temp;
    }

    return now < endDate && now > startDate;
};
