import { useEffect, useState, useRef } from '@wordpress/element';

const LazyImage = ({ onclick, classnames, styles, children, src, alt, srcSet }) => {
    const [shouldLoad, setShouldLoad] = useState(false);
    const placeholderRef = useRef(null);
    const [currentSrc, setCurrentSrc] = useState(null);

    useEffect(() => {
        if (window.innerWidth <= 768 && srcSet) {
            setCurrentSrc(srcSet);
        } else {
            setCurrentSrc(src);
        }
    }, [src, srcSet]);

    useEffect(() => {
        if (!shouldLoad && placeholderRef.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setShouldLoad(true);
                    }
                },
                {
                    rootMargin: '0px',
                    threshold: 0.01
                }
            );
            observer.observe(placeholderRef.current);
            return () => observer.disconnect();
        }
    }, [shouldLoad, placeholderRef]);

    if (currentSrc) {
        return shouldLoad ? (
            <img src={currentSrc} alt={alt || ''} className={classnames} onClick={onclick} />
        ) : (
            <div style={{ minHeight: '100px', minWidth: '100px' }} className={`lazy ${classnames || ''}`} ref={placeholderRef}></div>
        );
    }

    return shouldLoad ? (
        <div className={classnames} style={styles} onClick={onclick}>
            {children}
        </div>
    ) : (
        <div style={{ minHeight: '10px', minWidth: '10px' }} className={`lazy ${classnames || ''}`} ref={placeholderRef}></div>
    );
};

export default LazyImage;
