import { useState } from '@wordpress/element';
import { updateOrderInfo } from '../../api/methods';
import { getAddressSuggestions } from '../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';
import { selectCartData, updateCart } from '../../store/cart/cartSlice';

const AddressInput = () => {
    const dispatch = useDispatch();
    const retailerId = useSelector(selectRetailerId);
    const cartData = useSelector(selectCartData);

    const [suggestions, setSuggestions] = useState([]);
    const [textValue, setTextValue] = useState(
        cartData.address.city !== ''
            ? `${cartData.address.street1}, ${cartData.address.city}, ${cartData.address.state} ${cartData.address.zip}`
            : ''
    );

    return (
        <div className='headless-cart-delivery-inputs'>
            {cartData.address.city === '' ? (
                <div className='headless-cart-delivery-msg'>Please enter your delivery address</div>
            ) : (
                !cartData.address.deliverable && (
                    <div className='headless-cart-delivery-msg'>Address is outside delivery range!</div>
                )
            )}
            <input
                type='text'
                onChange={async (e) => {
                    setTextValue(e.target.value);

                    if (e.target.value !== '') {
                        const response = await getAddressSuggestions(
                            encodeURIComponent(e.target.value)
                        );

                        if (response.results) {
                            setSuggestions(
                                response.results.map((result) => result.address)
                            );
                        }
                    }
                }}
                value={textValue}
            ></input>

            <ul
                className='headless-delivery-autofill'
                style={{ display: suggestions.length > 0 ? 'block' : 'none' }}
            >
                {suggestions.map((suggestion, i) => (
                    <li
                        key={i}
                        onClick={async (e) => {
                            setTextValue(e.target.innerText);
                            setSuggestions([]);

                            const response = await updateOrderInfo(
                                retailerId,
                                cartData.id,
                                cartData.orderType,
                                cartData.pricingType,
                                {
                                    city: suggestion.municipality,
                                    state: suggestion.countrySubdivision,
                                    street1: `${suggestion.streetNumber} ${suggestion.streetName}`,
                                    street2: '',
                                    zip: suggestion.postalCode,
                                }
                            );

                            dispatch(updateCart(response));
                        }}
                    >
                        {suggestion.freeformAddress}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AddressInput;
