import SpecialCards from './SpecialCards';

const SpecialsGrid = ({
    atts,
    menuUrl,
    styleOptions,
    root,
}) => {
    return (
        <div className='headless-menu-builder-section'>
            {atts.title ? (
                <div className='headless-menu-builder-title'>
                    <div className='headless-shortcode-title-wrapper'>
                        <div className='headless-shortcode-title'>
                            <span>{atts.title}</span>
                        </div>
                        {atts.title && menuUrl ? (
                            <a
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    window.location.href =
                                        menuUrl + '?specials';
                                }}
                                className={`${
                                    styleOptions.buttonClass
                                        ? styleOptions.buttonClass +
                                          ' btn-sm headless-title-button'
                                        : 'headless-button headless-button-sm headless-title-button'
                                }`}
                            >
                                Shop all specials
                            </a>
                        ) : null}
                    </div>
                </div>
            ) : null}
            <SpecialCards atts={atts} menuUrl={menuUrl} root={root} />
        </div>
    );
};

export default SpecialsGrid;
