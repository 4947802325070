import { useState, useEffect, useRef } from '@wordpress/element';
import { filterTypes, menuFilter } from '../../config';
import startCase from 'lodash.startcase';
import isEqual from 'lodash.isequal';

const ProductNav = ({ selectedFilters, sort, setSort }) => {
    const [title, setTitle] = useState('All');
    const [showDropdown, setShowDropdown] = useState(false);

    const sortKeys = {
        name: 'NAME',
        popular: 'POPULAR',
        price: 'PRICE',
        potency: 'POTENCY',
    };

    const sortDirs = {
        asc: 'ASC',
        desc: 'DESC',
    };

    const getSortButtonText = (key) => {
        switch (key) {
            case 'POPULAR':
                return 'Popular';
            case 'NAME':
                return 'Brand';
            case 'PRICE':
                return sort.sortDir === sortDirs.asc
                    ? 'Price: Low to High'
                    : 'Price: High to Low';
            case 'POTENCY':
                return sort.sortDir === sortDirs.asc
                    ? 'Potency: Low to High'
                    : 'Potency: High to Low';
            default:
                return 'Sort By';
        }
    };

    /**
     * Title.
     */
    useEffect(() => {
        if (isEqual(selectedFilters, menuFilter)) {
            setTitle('All Products');
        } else if (selectedFilters[filterTypes.category]) {
            setTitle(
                `All ${startCase(
                    selectedFilters[filterTypes.category].toLowerCase()
                )}`
            );
        } else if (selectedFilters[filterTypes.specials]) {
            setTitle('All Specials');
        } else if (selectedFilters[filterTypes.staff_picks]) {
            setTitle('Staff Picks');
        } else {
            setTitle('');
        }
    }, [selectedFilters]);

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (
                showDropdown &&
                !dropdownRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [showDropdown]);

    return (
        <div className='headless-products-nav'>
            {title.length > 0 && (
                <div className='headless-products-nav--title'>{title}</div>
            )}
            <div className='headless-products-nav--sort'>
                <button
                    ref={buttonRef}
                    onClick={() => setShowDropdown(!showDropdown)}
                    className={`headless-products-nav-dropdown-btn`}
                >
                    <span>{getSortButtonText(sort.sortKey)}</span>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 320 512'
                    >
                        <path d='M320 240L160 384 0 240l0-48 320 0 0 48z' />
                    </svg>
                </button>
                <ul
                    ref={dropdownRef}
                    className='headless-sort-dropdown'
                    style={
                        showDropdown
                            ? {}
                            : { opacity: 0, pointerEvents: 'none' }
                    }
                >
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortDir: sortDirs.desc,
                                sortKey: sortKeys.popular,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Popular
                    </li>
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortKey: sortKeys.name,
                                sortDir:
                                    sort.sortDir === sortDirs.asc
                                        ? sortDirs.asc
                                        : sortDirs.desc,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Brand
                    </li>
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortKey: sortKeys.price,
                                sortDir: sortDirs.asc,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Price: Low to High
                    </li>
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortKey: sortKeys.price,
                                sortDir: sortDirs.desc,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Price: High to Low
                    </li>
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortKey: sortKeys.potency,
                                sortDir: sortDirs.asc,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Potency: Low to High
                    </li>
                    <li
                        style={{
                            listStyle: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSort({
                                sortKey: sortKeys.potency,
                                sortDir: sortDirs.desc,
                            });
                            setShowDropdown(false);
                        }}
                    >
                        Potency: High to Low
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProductNav;
