import FilterModuleCheckbox from './FilterModuleCheckbox';
import { filterTypes, menuFilter } from '../../config';
import FilterModuleRange from './FilterModuleRange';
import CaretToggle from './CaretToggle';

const FilterDesktop = ({
    selectedFilters,
    setSelectedFilters,
    filterTerms,
    handleChange,
    handleRangeChange,
    styleOptions,
    navbar,
}) => {
    const { categories, subcategories, strains, brands, effects, weights } =
        filterTerms;

    return (
        <div id='headless-filter-sidebar' className='headless-filter-sidebar'>
            {!navbar && (
                <CaretToggle title={'Category'}>
                    <FilterModuleCheckbox
                        filterType={filterTypes.category}
                        filters={categories}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                </CaretToggle>
            )}

            <CaretToggle title={'Subcategory'}>
                <FilterModuleCheckbox
                    filterType={filterTypes.subcategory}
                    filters={subcategories}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    handleChange={handleChange}
                />
            </CaretToggle>

            <CaretToggle title={'Strain'}>
                <FilterModuleCheckbox
                    filterType={filterTypes.strain}
                    filters={strains}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    handleChange={handleChange}
                />
            </CaretToggle>

            <CaretToggle title={'Effects'}>
                <FilterModuleCheckbox
                    filterType={filterTypes.effects}
                    filters={effects}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    handleChange={handleChange}
                />
            </CaretToggle>

            <CaretToggle title={'Weights'}>
                <FilterModuleCheckbox
                    filterType={filterTypes.weights}
                    filters={weights}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    handleChange={handleChange}
                />
            </CaretToggle>

            {!navbar && (
                <CaretToggle title={'Brand'}>
                    <FilterModuleCheckbox
                        filterType={filterTypes.brand}
                        filters={brands}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                </CaretToggle>
            )}

            <CaretToggle title={'THC Potency'}>
                <FilterModuleRange
                    filterType={filterTypes.potencyThc}
                    selectedFilters={selectedFilters}
                    handleChange={handleRangeChange}
                />
            </CaretToggle>

            <CaretToggle title={'CBD Potency'}>
                <FilterModuleRange
                    filterType={filterTypes.potencyCbd}
                    selectedFilters={selectedFilters}
                    handleChange={handleRangeChange}
                />
            </CaretToggle>

            <button
                id='clear-checkbox-filters'
                className={`${
                    styleOptions.buttonClass
                        ? styleOptions.buttonClass + ' btn-sm'
                        : 'clear-checkbox-filters-button'
                }`}
                onClick={() => {
                    setSelectedFilters(menuFilter);
                }}
            >
                Clear Filters
            </button>
        </div>
    );
};

export default FilterDesktop;
