import { formattedPrice } from './util';

const PriceSummary = ({ priceSummary }) => {
    const { subtotal, fees, taxes, total } = priceSummary;

    return (
        <div className='headless-cart-price-summary'>
            <div className='headless-cart-price-item'>
                <div>Subtotal</div>
                <div>{formattedPrice(subtotal, true)}</div>
            </div>
            <div className='headless-cart-price-item'>
                <div>Estimated service fees</div>
                <div>{formattedPrice(fees, true)}</div>
            </div>
            <div className='headless-cart-price-item'>
                <div>Estimated taxes</div>
                <div>{formattedPrice(taxes, true)}</div>
            </div>
            <div className='headless-cart-price-item headless-cart-price-total'>
                <div>Estimated total</div>
                <div className='headless-cart-price-total'>
                    {formattedPrice(total, true)}
                </div>
            </div>
        </div>
    );
};

export default PriceSummary;
