import { useState, useEffect } from '@wordpress/element';
import { getProduct } from '../api/methods';
import { ucwords } from '../util';
import ProductImage from '../components/SingleProduct/ProductImage';
import Category from '../components/SingleProduct/Category';
import AddToCart from '../components/SingleProduct/AddToCart';
import MobileNav from '../components/SingleProduct/MobileNav';
import Effects from '../components/SingleProduct/Effects';
import Cannabinoids from '../components/SingleProduct/Cannabinoids';
import Surfside from '../components/Surfside/Surfside';
import PotencyChart from '../components/SingleProduct/PotencyChart';
import LoadingSpinner from '../components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectRetailerData } from '../store/retailer/retailerSlice';

const SingleProduct = ({ data }) => {
    const {
        productId,
        surfside,
        shopEditor: {
            singleProductOptions: {
                showPotencyChart,
                showProductDescription,
                showProductEffects,
                showStaffPickBadge,
            },
            customStyleOptions,
        },
    } = data;

    const [product, setProduct] = useState(null);
    const retailerData = useSelector(selectRetailerData);

    const handleGoBack = () => {
        history.back();
    };

    /**
     * Get and set product.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // also set document title & meta description once product is received.
        const getAndSetProduct = async () => {
            setProduct(await getProduct(retailerData.id, productId, signal));
        };

        getAndSetProduct();

        return () => controller.abort();
    }, []);

    /**
     * Title and SEO.
     */
    useEffect(() => {
        if (product) {
            // remove ( and ) characters from shop title if found
            let retailerFormatted = retailerData.name
                .replace('(', ' - ')
                .replace(')', '');

            document.title = `${product.name} | Shop ${ucwords(
                product.category
            )} | ${retailerFormatted}`;

            const metaDescription =
                'Offered at ' +
                retailerFormatted +
                ', try ' +
                product.name +
                ' from ' +
                product.brand.name +
                ' today.';

            const metaDescriptionTag = document.querySelector(
                'meta[name="description"]'
            );

            if (metaDescriptionTag) {
                metaDescriptionTag.setAttribute('content', metaDescription);
            }
        }
    }, [product]);

    console.log({ product });

    if (!product) {
        return <LoadingSpinner />;
    } else {
        return (
            <>
                <MobileNav
                    imageUrl={product.images[0].url}
                    variants={product?.variants}
                    styleOptions={customStyleOptions}
                />
                {surfside.enable && surfside.showProductBanner ? (
                    <Surfside
                        placement='product'
                        accountId={surfside.account_id}
                        sourceId={surfside.source_id}
                        placementId={surfside.placement_ids.single_product}
                    />
                ) : null}
                <div
                    data-retail-location-id={retailerData.id}
                    className='range-headless headless-single-product'
                >
                    <div className='headless-single-product-back'>
                        <a
                            role='button'
                            onClick={handleGoBack}
                            className='headless-back-link'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='1em'
                                viewBox='0 0 448 512'
                            >
                                <path d='M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z' />
                            </svg>
                            <span>Continue shopping</span>
                        </a>
                    </div>
                    <div className='row'>
                        <ProductImage
                            url={product.images[0].url}
                            alt={product.description}
                        />
                        <div className='col-lg-6 col-xxl-7'>
                            <div className='headless-sp-primary'>
                                <Category
                                    category={product?.category}
                                    strainType={product?.strainType}
                                />
                                <h1 className='headless-sp-title pe-lg-5'>
                                    {product?.name}
                                </h1>
                                {product?.brand && (
                                    <div className='headless-sp-brand'>
                                        <span>By </span>
                                        <span>
                                            <a href='#'>
                                                {product?.brand?.name}
                                            </a>
                                        </span>
                                    </div>
                                )}
                                <AddToCart
                                    variants={product?.variants}
                                    styleOptions={customStyleOptions}
                                />
                                {showProductEffects && (
                                    <Effects effects={product?.effects} />
                                )}
                                {showPotencyChart && (
                                    <PotencyChart
                                        potencyThc={product?.potencyThc}
                                        potencyCbd={product?.potencyCbd}
                                    />
                                )}
                                {showProductDescription && (
                                    <div className='headless-sp-description'>
                                        <div className='headless-breadcrumb'>
                                            Product Description
                                        </div>
                                        <div className='headless-sp-content'>
                                            {product?.description}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SingleProduct;
