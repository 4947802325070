import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerData } from '../../store/retailer/retailerSlice';
import { selectCartData, updateCart } from '../../store/cart/cartSlice';
import { updateOrderInfo } from '../../api/methods';
import startCase from 'lodash.startcase';

const PricingType = () => {
    const dispatch = useDispatch();
    const retailerData = useSelector(selectRetailerData);
    const cartData = useSelector(selectCartData);

    return (
        <div className='headless-pricingtype-select-wrapper'>
            <span className='headless-pricingtype-select-title'>Menu</span>
            <select
                name='pricingtype-select'
                id='pricingtype-select'
                className='headless-pricingtype-select'
                onChange={async (e) => {
                    const response = await updateOrderInfo(
                        retailerData.id,
                        cartData.id,
                        cartData.orderType,
                        e.target.value
                    );

                    dispatch(updateCart(response));
                }}
                value={cartData.pricingType}
            >
                {retailerData.menuTypes.map((menuType, i) => (
                    <option key={i} value={menuType}>
                        {startCase(menuType.toLowerCase())}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PricingType;
