import { useState } from '@wordpress/element';

const CaretToggle = ({ children, title }) => {
    const [toggleFilterCaret, setToggleFilterCaret] = useState(true);

    return (
        <div
            className={`filter-sidebar-module ${
                toggleFilterCaret ? '' : 'filter-inactive'
            }`}
        >
            <div className='filter-sidebar-header'>
                <div className='filter-sidebar-title'>{title}</div>
                <div
                    className='filter-sidebar-caret'
                    role='button'
                    onClick={() => setToggleFilterCaret(!toggleFilterCaret)}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                    >
                        <path d='M212.7 331.3c6.2 6.2 16.4 6.2 22.6 0l160-160c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4 75.3 148.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l160 160z' />
                    </svg>
                </div>
            </div>
            {children}
        </div>
    );
};

export default CaretToggle;
