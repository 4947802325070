import FilterNavbarDesktop from './FilterNavbarDesktop';
import FilterNavbarMobile from './FilterNavbarMobile';

const FilterNavbar = ({ filterTerms, selectedFilters, setSelectedFilters }) => {
    return (
        <nav className='headless-filter-navbar'>
            <FilterNavbarDesktop
                filterTerms={filterTerms}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
            />
            <FilterNavbarMobile
                filterTerms={filterTerms}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
            />
        </nav>
    );
};

export default FilterNavbar;
