import { useState, useEffect, useRef } from '@wordpress/element';
import { addItem, updateQuantity } from '../../api/methods';
import { formattedPrice, percentageOff } from '../Cart/util';
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';
import {
    openSidebar,
    updateCart,
    selectCartData,
} from '../../store/cart/cartSlice';

const MobileNav = ({ imageUrl, variants, styleOptions }) => {
    const [errors, setErrors] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState(variants[0].id);
    const [quantityOption, setQuantityOption] = useState(10);
    const [priceOption, setPriceOption] = useState(variants[0].priceRec);

    const dispatch = useDispatch();
    const retailerId = useSelector(selectRetailerId);
    const cartData = useSelector(selectCartData);

    const handleGoBack = () => {
      history.back();
    };

    const containerRef = useRef(null);
    let lastKnownScrollPosition = 0;
    let ticking = false;

    const checkScrollPosition = (scrollPos) => {
        if (!containerRef.current) return;

        if (scrollPos >= 25) {
            containerRef.current.classList.add('spn-visible');
        } else {
            containerRef.current.classList.remove('spn-visible');
        }
    };

    useEffect(() => {
        setQuantityOption(
            variants.find((variant) => variant.id === selectedVariant).quantity
        );
        setPriceOption(
            variants
                .find((variant) => variant.id === selectedVariant)
                .priceRec.toFixed(2)
        );
    }, [selectedVariant]);

    useEffect(() => {
        const handleScroll = () => {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(() => {
                    checkScrollPosition(lastKnownScrollPosition);
                    ticking = false;
                });

                ticking = true;
            }
        };

        // Check initial position on component mount
        checkScrollPosition(window.scrollY);

        // Set up the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
          ref={containerRef}
          className='headless-single-product-nav'
        >
            <div
                className='headless-spn-back'
                role='button'
                onClick={handleGoBack}
            >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z'/></svg>
            </div>
            <div className='headless-spn-image'>
                <img
                    srcSet={`${imageUrl}?w=500 500w, ${imageUrl}?w=800 800w, ${imageUrl}?w=2000 2000w`}
                    sizes={
                        '(max-width: 576px) 500px, (max-width: 1799px) 800px, (min-width: 1800px) 2000px'
                    }
                    src={`${imageUrl}?w=500`}
                />
            </div>
            <div className='headless-spn-price'>
                {formattedPrice(
                    priceOption
                )}
            </div>
            <div
                className='headless-spn-cart'
                onClick={async () => {
                    const productId = selectedVariant.split('~')[0];
                    const option = selectedVariant.split('~')[1];

                    const existingItem = cartData.items.find(
                        (item) =>
                            item.productId === productId &&
                            item.option === option
                    );

                    let response;
                    if (existingItem) {
                        response = await updateQuantity(
                            retailerId,
                            cartData.id,
                            existingItem.id,
                            existingItem.quantity + quantity
                        );
                    } else {
                        response = await addItem(
                            retailerId,
                            cartData.id,
                            quantity,
                            selectedVariant
                        );
                    }

                    if (response.hasOwnProperty('errors')) {
                        setErrors(
                            response.errors.map((error) => {
                                if (error?.extensions?.errors) {
                                    return error.extensions.errors.map(
                                        (extError) => extError.detail
                                    );
                                } else {
                                    return error.message;
                                }
                            })
                        );
                    } else {
                        dispatch(updateCart(response));
                        dispatch(openSidebar());
                        setErrors([]);
                    }
                }}
            >
                <button
                    className={`${
                      styleOptions.buttonClass
                            ? styleOptions.buttonClass
                            : 'headless-button headless-button-lg headless-cart-button'
                    }`}
                >
                    <span><span className='spn-cart-tiny'>+</span><span className='spn-cart-normal'>Add to</span> Cart</span>
                </button>
            </div>
        </div>
    );
};

export default MobileNav;
