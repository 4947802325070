const CheckoutBtn = ({ url, styleOptions }) => {
    const handleCheckoutClick = () => {
        if (url === '') {
            if (
                confirm(
                    'Unfortunately there has been an issue with your checkout, please click OK to reload this page and try again.'
                )
            ) {
                location.reload();
            }
        } else {
            sessionStorage.setItem('checkoutUrl', url);
            window.location.href = '/checkout';
        }
    };

    return (
        <div className='headless-cart-sidebar-checkout' id='cart-checkout'>
            <a
                id='cart-checkout-btn'
                role='button'
                className={`${
                    styleOptions.buttonClass
                        ? styleOptions.buttonClass
                        : 'headless-button headless-cart-checkout-button'
                }`}
                onClick={handleCheckoutClick}
            >
                Go to Checkout
            </a>
        </div>
    );
};

export default CheckoutBtn;
