import { useState, useEffect } from '@wordpress/element';
import { getAllRetailers } from '../../api/methods';

const LocationModal = ({
    retailerConfig,
    shopEditor: { customStyleOptions },
    setRetailerId,
}) => {
    const [retailers, setRetailers] = useState([]);
    const [isVisible, setIsVisible] = useState(true);

    /**
     * Compare provided retailers against API availability and filter if needed.
     * Sets API data.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetFilteredRetailers = async () => {
            const retailersData = await getAllRetailers(signal);

            if (retailersData) {
                const retailerIdsInRetailerConfig = Object.keys(
                    retailerConfig.retailers
                );

                const filteredRetailers = retailersData.filter((retailer) =>
                    retailerIdsInRetailerConfig.includes(retailer.id)
                );

                setRetailers(filteredRetailers);
            }
        };

        getAndSetFilteredRetailers();

        return () => controller.abort();
    }, []);

    const closeModal = () => {
        setIsVisible(false);
    };

    const preventPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className={`headless-location-modal-overlay ${
                isVisible ? 'hlm-visible' : ''
            }`}
            onClick={closeModal}
            style={
                retailerConfig.locationModalBg
                    ? { backgroundImage: `url(${retailerConfig.locationModalBg})` }
                    : {}
            }
        >
            <div
                className='headless-location-modal-content'
                onClick={preventPropagation}
            >
                <span
                    className='headless-location-close-btn'
                    onClick={closeModal}
                >
                    &times;
                </span>
                <div className='headless-hlm-title'>Select your location</div>
                <div className='headless-hlm-locations'>
                    {retailers.map((retailer) => (
                        <div
                            className='headless-hlm-location'
                            data-retailer-id={retailer.id}
                            key={retailer.id}
                        >
                            <div className='headless-hlm-location-info'>
                                <div className='headless-hlm-location-name'>
                                    {retailer.name}
                                </div>
                                <div className='headless-hlm-location-address'>
                                    {retailer.address}
                                </div>
                            </div>
                            <div className='headless-hlm-location-btn'>
                                <a
                                    className={`${
                                        customStyleOptions?.buttonClass
                                            ? customStyleOptions.buttonClass +
                                              ' btn-sm'
                                            : 'headless-button headless-cart-button'
                                    }`}
                                    role='button'
                                    onClick={() => setRetailerId(retailer.id)}
                                >
                                    Select
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocationModal;
