import { menuFilter } from '../../config';
import MultiRangeSlider from 'multi-range-slider-react';

const FilterModuleRange = ({
    filterType,
    title = null,
    handleChange,
    selectedFilters,
    mobile = false,
}) => {
    return (
        <div
            className={`filter-sidebar-range ${
                mobile
                    ? 'filter-sidebar-range-mobile'
                    : 'filter-sidebar-options'
            }`}
        >
            {title && (
                <label htmlFor={`headless-range-slider-${filterType}`}>
                    {title}
                </label>
            )}
            <MultiRangeSlider
                id={`headless-range-slider-${filterType}`}
                min={menuFilter[filterType].min}
                max={menuFilter[filterType].max}
                step={1}
                minValue={selectedFilters[filterType].min}
                maxValue={selectedFilters[filterType].max}
                onChange={(e) => handleChange(e, filterType)}
                ruler={false}
            />
        </div>
    );
};

export default FilterModuleRange;
