import { createPortal, useEffect, useState } from '@wordpress/element';
import Shortcode from './Shortcode';
import CartSidebar from './components/Cart/CartSidebar';
import LocationModal from './components/LocationSelect/LocationModal';
import LoadingSpinner from './components/LoadingSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRetailerData } from './store/retailer/retailerSlice';
import { fetchOrCreateCart } from './store/cart/cartSlice';
import { getRetailerId } from './util';

const App = ({ data = null, rootKey }) => {
    let datasets = data || rangeHeadlessData;
    let {
        retailer = null,
        retailerConfig = null,
        shopEditor = null,
        filterTerms = null,
        surfside = null,
    } = datasets[rootKey];

    const [retailerId, setRetailerId] = useState(getRetailerId(retailer));

    const renderCartSidebar = Object.values(datasets).find(
        (dataset) =>
            dataset.shortcode === 'singleproduct' ||
            dataset.shortcode === 'headlessproducts' ||
            dataset.shortcode === 'headlessmenu'
    );

    const dispatch = useDispatch();
    const retailerDataStatus = useSelector((state) => state.retailer.status);
    const cartDataStatus = useSelector((state) => state.cart.status);

    const [domReady, setDomReady] = useState(false);
    useEffect(() => {
        setDomReady(true);
    }, []);

    useEffect(() => {
        if (retailerId) {
            if (retailerDataStatus === 'idle') {
                dispatch(fetchRetailerData(retailerId));
            }
            if (cartDataStatus === 'idle') {
                dispatch(fetchOrCreateCart(retailerId));
            }
        }
    }, [retailerId, retailerDataStatus, cartDataStatus, dispatch]);

    if (!retailerId) {
        return createPortal(
            <LocationModal
                retailerConfig={retailerConfig}
                shopEditor={shopEditor}
                setRetailerId={setRetailerId}
            />,
            document.body
        );
    } else if (retailerDataStatus === 'error') {
        return <p>Shop not found! Please try another location.</p>;
    } else if (
        retailerDataStatus === 'idle' ||
        cartDataStatus === 'idle' ||
        !domReady
    ) {
        return <LoadingSpinner />;
    } else if (
        domReady &&
        retailerDataStatus === 'succeeded' &&
        cartDataStatus === 'succeeded'
    ) {
        return (
            <>
                {Object.entries(datasets).map(([key, dataset], i) => {
                    let rootId = `range-headless-root-${key}`;
                    let root = document.getElementById(rootId);

                    const backendLoadingSpinner = document.getElementById(
                        `loading-${key}`
                    );
                    if (backendLoadingSpinner) {
                        backendLoadingSpinner.remove();
                    }

                    if (key === rootKey) {
                        return <Shortcode key={i} dataset={dataset} />;
                    } else {
                        return createPortal(
                            <Shortcode
                                key={i}
                                dataset={{
                                    ...dataset,
                                    retailer,
                                    retailerConfig,
                                    shopEditor,
                                    filterTerms,
                                    surfside,
                                }}
                            />,
                            root
                        );
                    }
                })}
                {renderCartSidebar &&
                    createPortal(
                        <CartSidebar
                            styleOptions={shopEditor?.customStyleOptions}
                        />,
                        document.body
                    )}
            </>
        );
    }
};

export default App;
