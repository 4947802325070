import { useState } from '@wordpress/element';
import FilterDesktop from './FilterDesktop';
import FilterMobile from './FilterMobile';
import SearchBar from './SearchBar';
import { filterTypes, menuFilter } from '../../config';

const Filter = ({
    filterTerms,
    selectedFilters,
    setSelectedFilters,
    styleOptions,
    navbar,
}) => {
    /**
     * Filter logic for desktop and mobile.
     * @param {EventTarget} target
     * @param {string} filterType
     */
    const handleChange = (target, filterType) => {
        /**
         * Staff picks.
         */
        if (
            filterType === filterTypes.category &&
            target.value === filterTypes.staff_picks
        ) {
            setSelectedFilters({
                ...menuFilter,
                [filterTypes.staff_picks]: !selectedFilters.staff_picks,
                [filterTypes.specials]: false,
            });
            /**
             * Specials.
             */
        } else if (
            filterType === filterTypes.category &&
            target.value === filterTypes.specials
        ) {
            setSelectedFilters({
                ...menuFilter,
                [filterTypes.specials]: !selectedFilters.specials,
                [filterTypes.staff_picks]: false,
            });
            /**
             * Effects or weights (array).
             */
        } else if (
            filterType === filterTypes.effects ||
            filterType === filterTypes.weights
        ) {
            if (target.value === '') {
                setSelectedFilters({
                    ...selectedFilters,
                    [filterType]: [],
                    [filterTypes.specials]: false,
                    [filterTypes.staff_picks]: false,
                });
            } else {
                setSelectedFilters({
                    ...selectedFilters,
                    [filterType]: selectedFilters[filterType].includes(
                        target.value
                    )
                        ? [
                              ...selectedFilters[filterType].filter(
                                  (filter) => filter !== target.value
                              ),
                          ]
                        : [...selectedFilters[filterType], target.value],
                    [filterTypes.specials]: false,
                    [filterTypes.staff_picks]: false,
                });
            }
            /**
             * All other filters.
             */
        } else {
            setSelectedFilters({
                ...selectedFilters,
                [filterType]:
                    selectedFilters[filterType] === target.value
                        ? ''
                        : target.value,
                [filterTypes.specials]: false,
                [filterTypes.staff_picks]: false,
            });
        }
    };

    /**
     * Filter logic for range sliders (potency).
     * @param {object} e MultiRangeSlider event object.
     * @param {string} filterType
     */
    const handleRangeChange = (e, filterType) => {
        setSelectedFilters({
            ...selectedFilters,
            [filterType]: {
                ...selectedFilters[filterType],
                min: e.minValue,
                max: e.maxValue,
            },
        });
    };

    const [toggleAllFilters, setToggleAllFilters] = useState(false);

    return (
        <>
            <div
                className='headless-filter-toggle-all'
                onClick={() => setToggleAllFilters(!toggleAllFilters)}
            >
                {toggleAllFilters ? 'Hide Filters' : 'More Filters'}
            </div>
            <div
                className={`headless-filter-wrapper ${
                    toggleAllFilters ? '' : 'hide-filters'
                }`}
            >
                {!navbar && (
                    <SearchBar
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                    />
                )}

                <FilterMobile
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    filterTerms={filterTerms}
                    handleChange={handleChange}
                    handleRangeChange={handleRangeChange}
                    styleOptions={styleOptions}
                />
                <FilterDesktop
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    filterTerms={filterTerms}
                    handleChange={handleChange}
                    handleRangeChange={handleRangeChange}
                    styleOptions={styleOptions}
                    navbar={navbar}
                />
            </div>
        </>
    );
};

export default Filter;
