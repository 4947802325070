import { getExistingCheckout, cleanMenuFilterForQuery } from '../util';
import {
    createCheckout,
    fetchCartDetails,
    menuQuery,
    addItemToCheckout,
    removeItemFromCheckout,
    productQuery,
    getSpecialsList,
    menuBySpecialsSection,
    menuByCustomSection,
    updateItemQuantity,
    updateCheckout,
    retailerQuery,
    retailersQuery,
    menuByStaffPicks,
} from './api';

/**
 * Create new cart.
 *
 * @param {string} retailerId Retailer ID.
 * @param {AbortSignal} signal
 *
 * @returns {object|error}
 */
const getNewCart = async (retailerId, signal) => {
    window.localStorage.removeItem('DUTCHIE_CHECKOUT');

    try {
        const response = await createCheckout(
            retailerId,
            'PICKUP',
            'RECREATIONAL',
            signal
        );

        let cart = response?.data?.createCheckout;

        // set localStorage.
        if (cart) {
            window.localStorage.setItem(
                'DUTCHIE_CHECKOUT',
                JSON.stringify({
                    checkoutId: cart.id,
                    retailerId: retailerId,
                })
            );
        }
        return cart;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Load existing cart.
 *
 * @param {string} retailerId Retailer ID.
 * @param {string} checkoutId Checkout ID.
 * @param {AbortSignal} signal
 *
 * @returns {object|error}
 */
const loadExistingCart = async (retailerId, checkoutId, signal) => {
    const response = await fetchCartDetails(retailerId, checkoutId, signal);

    if (response && response.hasOwnProperty('errors')) {
        window.localStorage.removeItem('DUTCHIE_CHECKOUT');

        try {
            const response = await createCheckout(
                retailerId,
                'PICKUP',
                'RECREATIONAL',
                signal
            );

            cart = response?.data.createCheckout;

            // set localStorage.
            window.localStorage.setItem(
                'DUTCHIE_CHECKOUT',
                JSON.stringify({
                    checkoutId: cart.id,
                    retailerId: retailerId,
                })
            );

            return cart;
        } catch (error) {
            console.error('error', error);
            return error;
        }
    } else {
        return response?.data.checkout;
    }
};

/**
 * Gets existing cart from localstorage data, or creates a new cart.
 *
 * @param {string} retailerId Current retail id.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getOrCreateCart = async (retailerId, signal = null) => {
    const existingCheckout = getExistingCheckout();

    // if cart info exists & matches current location.
    if (existingCheckout) {
        const { checkoutId } = existingCheckout;
        const lsRetailerId = existingCheckout.retailerId;

        if (retailerId === lsRetailerId) {
            return await loadExistingCart(lsRetailerId, checkoutId, signal);
        } else {
            return await getNewCart(retailerId, signal);
        }
    } else {
        return await getNewCart(retailerId, signal);
    }
};

/**
 * Get all menu products from a retailer.
 *
 * @param {string} retailerId Current retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {menuFilter} object menuFilter config.
 * @param {menuType} string Recreation or Medicinal.
 * @param {sort} object Sort direction and key
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getProducts = async (
    retailerId,
    offset,
    limit,
    menuFilter,
    menuType,
    sort,
    signal = null
) => {
    try {
        const response = await menuQuery(
            retailerId,
            offset,
            limit,
            cleanMenuFilterForQuery(menuFilter),
            menuType,
            sort,
            signal
        );

        if (response && response?.hasOwnProperty('errors')) {
            console.error(
                response.errors,
                'Does the retailerId provided to the shortcode correspond with the current API? Or perhaps the provided menuFilter is incorrect?',
                { menuFilter: cleanMenuFilterForQuery(menuFilter) }
            );
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get specials list by Retail ID.
 *
 * @param {string} retailerId Retail ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getSpecialsInfo = async (retailerId, signal = null) => {
    try {
        const response = await getSpecialsList(retailerId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.specials;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get specials menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {array} specialId Specials ID.
 * @param {string} menuType Recreational or Medicinal.
 * @param {sort} object Sort direction and key.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getSpecialsMenu = async (
    retailerId,
    offset,
    limit,
    specialId,
    menuType,
    sort,
    signal = null
) => {
    try {
        const response = await menuBySpecialsSection(
            retailerId,
            offset,
            limit,
            specialId,
            menuType,
            sort,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get custom menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} sectionType Section type 'CUSTOM_TYPE'.
 * @param {string} sectionName Section name 'custom section name'.
 * @param {string} menuType Recreational or Medicinal.
 * @param {object} sort Sort direction and key.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getCustomMenu = async (
    retailerId,
    offset,
    limit,
    sectionType,
    sectionName,
    menuType,
    sort,
    signal = null
) => {
    try {
        const response = await menuByCustomSection(
            retailerId,
            offset,
            limit,
            sectionType,
            sectionName,
            menuType,
            sort,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get staff picks menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} menuSectionFilterType Menu filter type. Default 'STAFF_PICKS'.
 * @param {string} menuType Recreational or Medicinal.
 * @param {object} sort Sort direction and key.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getStaffPicksMenu = async (
    retailerId,
    offset,
    limit,
    menuSectionFilterType = 'STAFF_PICKS',
    menuType,
    sort,
    signal = null
) => {
    try {
        const response = await menuByStaffPicks(
            retailerId,
            offset,
            limit,
            menuSectionFilterType,
            menuType,
            sort,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Add item to checkout object.
 *
 * @param {string} retailerId Current retail ID.
 * @param {string} cartId Current cart ID.
 * @param {int} quantity Quantity to add.
 * @param {string} variantId Variant ID.
 *
 * @returns {void|error}
 */
export const addItem = async (retailerId, cartId, quantity, variantId) => {
    // split into product id and variant option.
    const variant = variantId.split('~');
    let newCartId = false;

    try {
        const response = await addItemToCheckout(
            retailerId,
            newCartId || cartId,
            variant[0],
            quantity,
            variant[1]
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
            return response;
        } else {
            return response.data.addItem;
        }
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * Update item quantity.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} itemId
 * @param {int} quantity
 *
 * @returns {object|error}
 */
export const updateQuantity = async (
    retailerId,
    checkoutId,
    itemId,
    quantity
) => {
    const response = await updateItemQuantity(
        retailerId,
        checkoutId,
        itemId,
        quantity
    );

    try {
        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
            return response;
        }

        return response?.data?.updateQuantity;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Update checkout object.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} orderType
 * @param {string} pricingType
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const updateOrderInfo = async (
    retailerId,
    checkoutId,
    orderType,
    pricingType,
    address = {
        city: '',
        state: '',
        street1: '',
        street2: '',
        zip: '',
    },
    signal = null
) => {
    const response = await updateCheckout(
        retailerId,
        checkoutId,
        orderType,
        pricingType,
        address,
        signal
    );

    try {
        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.updateCheckout;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Remove item from checkout object.
 *
 * @param {string} retailerId Current retail ID.
 * @param {string} cartId Current cart ID.
 * @param {string} itemId Item ID.
 *
 * @returns {object|void|error}
 */
export const removeItem = async (retailerId, cartId, itemId) => {
    try {
        const response = await removeItemFromCheckout(
            retailerId,
            cartId,
            itemId
        );

        if (response && response.hasOwnProperty('errors')) {
            return console.error(response.errors);
        }

        return response.data.removeItem;
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * Get single product.
 *
 * @param {string} retailerId Retail ID.
 * @param {string} productId Product ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getProduct = async (retailerId, productId, signal = null) => {
    try {
        const response = await productQuery(retailerId, productId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.product;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get retailer data.
 *
 * @param {string} retailerId Retail ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|false|error}
 */
export const getRetailerData = async (retailerId, signal = null) => {
    try {
        const response = await retailerQuery(retailerId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(
                response.errors,
                'Is the retailerId provided to the shortcode correct and corresponds with the current API?',
                { retailerId }
            );
            return response.errors;
        }

        return response?.data?.retailer;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get all retailers.
 *
 * @param {AbortSignal|null} signal
 *
 * @returns {object|false|error}
 */
export const getAllRetailers = async (signal = null) => {
    try {
        const response = await retailersQuery(signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
            return response.errors;
        }

        return response?.data?.retailers;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Add a product to Surfside for tracking.
 *
 * @param {object} product - Product object.
 * @param {object} selectedVariant - Variant object.
 *
 * @returns {void}
 */
export function addProductToSurfside(product, selectedVariant) {
    if (window.surf) {
        console.log('tracking product in surfside');
        console.log([product.id,product.name,null,product.brand.name,product.category,selectedVariant.opion,selectedVariant.priceRec,1,null,null,'USD']);
        window.surf(
            'addProduct',
            product.id,                       // Product ID
            product.name,                     // Product name
            null,                             // Product list
            product.brand.name,               // Product brand
            product.category,                 // Product category
            selectedVariant.option,           // Product variant
            selectedVariant.priceRec,         // Product price
            1,                                // Product quantity
            null,                             // Product coupon
            null,                             // Product position
            'USD'                             // Product currency
        );
        window.surf('setCommerceAction', 'add');
    } else {
        console.error("Surfside SDK is not initialized.");
    }
}

/**
 * Scroll to the shop container.
 * @returns {void}
 */
 export const scrollToShop = () => {
    const shopElement = document.getElementById('shop');
    if (shopElement) {
        const topPosition = shopElement.getBoundingClientRect().top + window.pageYOffset - 150;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
};
