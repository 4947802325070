import Surfside from '../components/Surfside/Surfside';
import { getExistingCheckout } from '../util';

const Checkout = ({ data }) => {
    const checkoutUrl = sessionStorage.getItem('checkoutUrl') || null;

    const { surfside } = data;

    const checkout = getExistingCheckout();

    return (
        <div className='headless-checkout-container'>
            {surfside.enable &&
            surfside.showCheckoutBanner &&
            checkout.retailerId ? (
                <Surfside
                    placement='checkout'
                    accountId={surfside.account_id}
                    sourceId={surfside.source_id}
                    placementId={surfside.placement_ids.checkout}
                />
            ) : null}
            {checkoutUrl ? (
                <>
                    <div className='headless-checkout-wrapper'>
                        <iframe
                            src={checkoutUrl}
                            frameborder='0'
                            width='100%'
                            className='headless-checkout-iframe'
                        ></iframe>
                    </div>
                </>
            ) : (
                <div className='headless-checkout-fail'>
                    Sorry, we didn't find a valid checkout for your cart. Please
                    go back to your <a href='/cart'>Cart</a> and try again.
                </div>
            )}
        </div>
    );
};

export default Checkout;
