import { useState } from '@wordpress/element';
import { filterTypes, menuFilter } from '../../config';
import FilterNavbarDropdown from './FilterNavbarDropdown';
import SearchBar from './SearchBar';
import PricingType from '../Cart/PricingType';
import OpenOrClosed from './OpenOrClosed';
import FilterNavbarCartIcon from './FilterNavbarCartIcon';

const FilterNavbarMobile = ({
    filterTerms,
    selectedFilters,
    setSelectedFilters,
}) => {
    const { categories, brands } = filterTerms;

    const [menuView, setMenuView] = useState(false);
    const [menuViewFilter, setMenuViewFilter] = useState(filterTypes.category);

    return (
        <div className='filter-navbar filter-navbar--mobile'>
            {menuView ? (
                <div className='navbar-top navbar-top--menuview'>
                    <div className='navbar-top--left'>
                        <OpenOrClosed />
                    </div>
                    <div className='navbar-top--right'>
                        <div
                            onClick={() => setMenuView(false)}
                            className='filter-navbar-close-icon'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='navbar-top'>
                    <div className='navbar-top--left'>
                        <button onClick={() => setMenuView(true)}>Menu</button>
                    </div>

                    <div className='navbar-top--right'>
                        <SearchBar
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                        />
                        <FilterNavbarCartIcon />
                    </div>
                </div>
            )}

            <div className='headless-hr'></div>

            {menuView ? (
                <>
                    <div className='navbar-bottom--menuview'>
                        <div className='navbar-home'>
                            <button
                                onClick={() => {
                                    setMenuView(false);
                                    setSelectedFilters({
                                        ...menuFilter,
                                    });
                                }}
                            >
                                Home
                            </button>
                        </div>
                        <FilterNavbarDropdown
                            title={'Categories'}
                            filters={categories}
                            filterType={filterTypes.category}
                            setSelectedFilters={setSelectedFilters}
                            mobile
                            menuViewFilter={menuViewFilter}
                            setMenuViewFilter={setMenuViewFilter}
                            setMenuView={setMenuView}
                        />
                        <FilterNavbarDropdown
                            title={'Brands'}
                            filters={brands}
                            filterType={filterTypes.brand}
                            setSelectedFilters={setSelectedFilters}
                            mobile
                            menuViewFilter={menuViewFilter}
                            setMenuViewFilter={setMenuViewFilter}
                            setMenuView={setMenuView}
                        />
                        <div className='navbar-specials'>
                            <button
                                onClick={() => {
                                    setSelectedFilters({
                                        ...menuFilter,
                                        [filterTypes.specials]: true,
                                    });
                                    setMenuView(false);
                                }}
                            >
                                Specials
                            </button>
                        </div>
                    </div>
                    <div
                        id='filter-nav-menuview-portal'
                        className='navbar-bottom--menuview__portal'
                    ></div>
                </>
            ) : (
                <div className='navbar-bottom'>
                    <div className='navbar-bottom--left'>
                        <OpenOrClosed />
                    </div>
                    <div className='navbar-bottom--right'>
                        <PricingType />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterNavbarMobile;
