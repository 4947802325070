import OrderType from './OrderType';
import { formattedPrice, totalItemsInCart } from './util';
import CartProducts from './CartProducts';
import { useDispatch, useSelector } from 'react-redux';
import {
    toggleSidebar,
    selectSidebarToggle,
    selectCartData,
} from '../../store/cart/cartSlice';
import CartIcon from '../CartIcon';

const CartSidebar = ({ styleOptions }) => {
    const dispatch = useDispatch();
    const toggle = useSelector(selectSidebarToggle);
    const cartData = useSelector(selectCartData);

    return (
        <>
            <div
                className='headless-cart-icon'
                role='button'
                onClick={() => dispatch(toggleSidebar())}
            >
               <CartIcon />
            </div>

            <div
                id='cart-sidebar'
                className={`headless-cart-sidebar position-fixed vh-100 d-flex flex-column bg-white ${
                    toggle ? 'headless-show-sidebar' : ''
                }`}
            >
                <div className='headless-cart-sidebar-header'>
                    <div
                        className='headless-cart-sidebar-items'
                        id='cart-items'
                    >
                        <div className='headless-subtitle headless-cart-sidebar-title'>
                            Shopping cart
                        </div>
                    </div>
                    <div
                        className='headless-cart-sidebar-close closeCart'
                        role='button'
                        onClick={() => dispatch(toggleSidebar())}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                        >
                            <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z' />
                        </svg>
                    </div>
                </div>

                <OrderType />

                <div className='headless-cart-sidebar-contents' id='cart-data'>
                    <CartProducts />
                </div>
                <div className='headless-cart-sidebar-bottom'>
                    <div
                        className='headless-cart-sidebar-totals'
                        id='cart-totals'
                    >
                        <p>
                            Current subtotal:{' '}
                            {formattedPrice(cartData.priceSummary.subtotal, true)}
                        </p>
                    </div>
                    <div
                        className='headless-cart-sidebar-checkout'
                        id='cart-checkout'
                    >
                        <a
                            id='cart-checkout-btn'
                            className={`${
                                styleOptions?.buttonClass
                                    ? styleOptions.buttonClass
                                    : 'headless-button headless-cart-button'
                            }`}
                            href={`${window.location.origin}/cart`}
                        >
                            Go to Checkout
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartSidebar;
