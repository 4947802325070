import { useState, useEffect } from '@wordpress/element';
import { toLowerWithDashes } from '../../util';
import { useDispatch, useSelector } from 'react-redux';
import {
    openSidebar,
    selectCartData,
    updateCart,
} from '../../store/cart/cartSlice';
import { selectRetailerData } from '../../store/retailer/retailerSlice';
import ProductList from './ProductList';
import ProductCard from './ProductCard';
import { addItem, addProductToSurfside } from '../../api/methods';

const Product = ({
    product,
    display,
    styleOptions,
    specialProduct,
    surfside,
    surfsideProduct
}) => {
    const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

    const dispatch = useDispatch();
    const retailerData = useSelector(selectRetailerData);
    const cartData = useSelector(selectCartData);
    const pricingType = useSelector((state) => state.cart.data.pricingType);

    const medPricingTypes = {
        standard: 'priceMed',
        special: 'specialPriceMed',
    };

    const recPricingTypes = {
        standard: 'priceRec',
        special: 'specialPriceRec',
    };

    const [pricingTypes, setPricingTypes] = useState(
        pricingType === 'RECREATIONAL' ? recPricingTypes : medPricingTypes
    );

    const [errors, setErrors] = useState([]);

    const singleProductUrl = `${
        window.location.origin
    }/menu/product/${toLowerWithDashes(retailerData.name)}/${toLowerWithDashes(
        product.name
    )}/${retailerData.id}_${product.id}/`;

    const handleAddItem = async () => {
        if (surfside) {
            addProductToSurfside(product, selectedVariant);
        }

        let response = await addItem(
            retailerData.id,
            cartData.id,
            1,
            selectedVariant.id
        );

        if (response.hasOwnProperty('errors')) {
            setErrors(
                response.errors.map((error) => {
                    if (error?.extensions?.errors) {
                        return error.extensions.errors.map(
                            (extError) => extError.detail
                        );
                    } else {
                        return error.message;
                    }
                })
            );
        } else {
            dispatch(updateCart(response));
            dispatch(openSidebar());
            setErrors([]);
        }
    };

    /**
     * Keep pricing type current.
     */
    useEffect(() => {
        setPricingTypes(
            pricingType === 'RECREATIONAL' ? recPricingTypes : medPricingTypes
        );
    }, [pricingType]);

    /**
     * Update variant data when products are updated.
     */
    useEffect(() => {
        setSelectedVariant(product.variants[0]);
    }, [product]);

    return (
        <>
            {display === 'list' ? (
                <ProductList
                    product={product}
                    specialProduct={specialProduct}
                    singleProductUrl={singleProductUrl}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={setSelectedVariant}
                    styleOptions={styleOptions}
                    handleAddItem={handleAddItem}
                    pricingType={pricingTypes}
                    surfsideProduct={surfsideProduct}
                    errors={errors}
                />
            ) : (
                <ProductCard
                    product={product}
                    specialProduct={specialProduct}
                    singleProductUrl={singleProductUrl}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={setSelectedVariant}
                    styleOptions={styleOptions}
                    handleAddItem={handleAddItem}
                    pricingType={pricingTypes}
                    surfsideProduct={surfsideProduct}
                    errors={errors}
                />
            )}
        </>
    );
};

export default Product;
