import PickupOrDelivery from '../components/Cart/PickupOrDelivery';
import RetailerInfo from '../components/Cart/RetailerInfo';
import CartProducts from '../components/Cart/CartProducts';
import Surfside from '../components/Surfside/Surfside';

const Cart = ({ data }) => {
    const {
        surfside,
        shopEditor: { customStyleOptions },
    } = data;

    return (
        <div className='headless-cart-container'>
            {surfside.enable && surfside.showCartBanner ? (
                <Surfside
                    placement='cart'
                    accountId={surfside.account_id}
                    sourceId={surfside.source_id}
                    placementId={surfside.placement_ids.cart}
                />
            ) : null}
            <div className='headless-cart-page'>
                <div className='headless-cart-col headless-cart-left'>
                    <RetailerInfo />
                    <CartProducts />
                </div>
                <div className='headless-cart-col headless-cart-right'>
                    <PickupOrDelivery styleOptions={customStyleOptions} />
                </div>
            </div>
        </div>
    );
};

export default Cart;
