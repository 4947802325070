import Special from './Special';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSpecialsList,
    setSelectedSpecialId,
} from '../../store/specials/specialsSlice';

const Specials = ({ selectedSpecial }) => {
    const dispatch = useDispatch();
    const specials = useSelector(selectSpecialsList);

    return (
        <div className='headless-specials'>
            {!selectedSpecial ? (
                specials.map((special, i) => (
                    <Special
                        key={i}
                        special={special}
                        singleSpecialView={false}
                    />
                ))
            ) : (
                <>
                    <div
                        className='headless-specials-view-all'
                        onClick={() => dispatch(setSelectedSpecialId(null))}
                    >
                        All Specials
                    </div>
                    <Special
                        special={selectedSpecial}
                        singleSpecialView={true}
                    />
                </>
            )}
        </div>
    );
};

export default Specials;
