

const Cannabinoids = ({ cannabinoids }) => {
    console.log(cannabinoids);

    return (
        <>
            {cannabinoids.length > 0 ? (
                <div className='headless-sp-cannabinoids'>
                    <div className='headless-breadcrumb'>
                        Cannabinoid Profile
                    </div>
                    <div className='sp-cannabinoid-charts'>
                        {cannabinoids.map((cannabinoid, i) => (
                            <div className='sp-cannabinoid'>
                                <div className='sp-cannabinoid-chart' style={
                                    {
                                        backgroundImage: `
                                            conic-gradient(#0b99e6 0deg ${
                                                cannabinoid.value * 360
                                            }deg, #f2f2f2
                                            ${360 - cannabinoid.value * 360}deg)`,
                                    }
                                }>
                                    <span className='sp-cannabinoid-name'>
                                        {cannabinoid.cannabinoid.name.replace(/ *\([^)]*\) */g, "")}
                                    </span>
                                </div>
                                <div className='sp-cannabinoid-number'>
                                    {cannabinoid.value * 100}%
                                </div>
                            </div>
                        ))}
                    </div>
              </div>
            ) : null}
        </>
    );
};

export default Cannabinoids;
