import { filterTypes } from '../../config';
import startCase from 'lodash.startcase';

const FilterModuleCheckbox = ({
    filterType,
    filters,
    selectedFilters,
    handleChange,
    mobile = false,
    title = null,
}) => {
    return (
        <>
            {mobile && (
                <div className='filter-sidebar-header'>
                    <div className='filter-sidebar-title'>{title}</div>
                </div>
            )}
            <div
                style={mobile ? { maxHeight: '150px', overflowY: 'auto' } : {}}
            >
                <div className='filter-sidebar-options'>
                    {filters.map(
                        (filter, i) =>
                            filter !== 'NOT_APPLICABLE' &&
                            filter !== 'DEFAULT' && (
                                <div key={i} className='filter-option'>
                                    <input
                                        className='headless_category_checkbox headless_filter_checkbox'
                                        type='checkbox'
                                        checked={
                                            (filter === filterTypes.specials &&
                                                selectedFilters[
                                                    filterTypes.specials
                                                ]) ||
                                            (filter ===
                                                filterTypes.staff_picks &&
                                                selectedFilters[
                                                    filterTypes.staff_picks
                                                ]) ||
                                            selectedFilters[filterType] ===
                                                filter ||
                                            selectedFilters[filterType] ===
                                                filter?.id ||
                                            selectedFilters?.effects.includes(
                                                filter
                                            ) ||
                                            selectedFilters?.weights.includes(
                                                filter
                                            )
                                        }
                                        id={`headless_category_${
                                            filter?.name || filter
                                        }`}
                                        name={`headless_category_${
                                            filter?.name || filter
                                        }`}
                                        value={filter?.id || filter}
                                        onChange={(e) =>
                                            handleChange(e.target, filterType)
                                        }
                                    />
                                    <label
                                        htmlFor={`headless_category_${
                                            filter?.name || filter
                                        }`}
                                    >
                                        {filterType === filterTypes.weights
                                            ? filter
                                            : startCase(
                                                  filter?.name
                                                      ? filter.name.toLowerCase()
                                                      : filter.toLowerCase()
                                              )}
                                    </label>
                                </div>
                            )
                    )}
                </div>
            </div>
        </>
    );
};

export default FilterModuleCheckbox;
