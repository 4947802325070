import { filterTypes, menuFilter } from '../../config';
import FilterNavbarDropdown from './FilterNavbarDropdown';
import SearchBar from './SearchBar';
import PricingType from '../Cart/PricingType';
import OpenOrClosed from './OpenOrClosed';
import FilterNavbarCartIcon from './FilterNavbarCartIcon';

const FilterNavbarDesktop = ({
    filterTerms,
    selectedFilters,
    setSelectedFilters,
}) => {
    const { categories, brands } = filterTerms;

    return (
        <div className='filter-navbar filter-navbar--desktop'>
            <div className='navbar-left'>
                <div className='navbar-home'>
                    <button
                        onClick={() =>
                            setSelectedFilters({
                                ...menuFilter,
                            })
                        }
                    >
                        Home
                    </button>
                </div>
                <FilterNavbarDropdown
                    title={'Categories'}
                    filters={categories}
                    filterType={filterTypes.category}
                    setSelectedFilters={setSelectedFilters}
                />
                <FilterNavbarDropdown
                    title={'Brands'}
                    filters={brands}
                    filterType={filterTypes.brand}
                    setSelectedFilters={setSelectedFilters}
                />
                <div className='navbar-specials'>
                    <button
                        onClick={() =>
                            setSelectedFilters({
                                ...menuFilter,
                                [filterTypes.specials]: true,
                            })
                        }
                    >
                        Specials
                    </button>
                </div>
                <SearchBar
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
            </div>

            <div className='navbar-right'>
                <OpenOrClosed />

                <span className='filter-navbar-divider'> | </span>

                <PricingType />

                <span className='filter-navbar-divider'> | </span>

               <FilterNavbarCartIcon />
            </div>
        </div>
    );
};

export default FilterNavbarDesktop;
