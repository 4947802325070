import App from './App';
import isEmpty from 'lodash.isempty';
import { createRoot, render } from '@wordpress/element';
import store from './store/store';
import { Provider } from 'react-redux';

/**
 * Import the stylesheet for the plugin.
 */
import './style/main.scss';

/**
 * Non-block themes.
 */
if (!isEmpty(rangeHeadlessData)) {
    // main root that all shortcode instances will be rendered from, using portals.
    let rootKey = Object.entries(rangeHeadlessData).find(([k, v]) =>
        v.hasOwnProperty('mainRoot')
    )[0];

    /**
     * React 18+
     */
    if (createRoot) {
        createRoot(
            document.getElementById(`range-headless-root-${rootKey}`)
        ).render(
            <React.StrictMode>
                <Provider store={store}>
                    <App rootKey={rootKey} />
                </Provider>
            </React.StrictMode>
        );

        /**
         * Pre-React 18
         */
    } else {
        render(
            <React.StrictMode>
                <Provider store={store}>
                    <App rootKey={rootKey} />
                </Provider>
            </React.StrictMode>,
            document.getElementById(`range-headless-root-${rootKey}`)
        );
    }

    /**
     * Block themes.
     * `rangeHeadlessData` not available globally due to changes with how `wp_add_inline_script` is handled.
     * @see https://core.trac.wordpress.org/ticket/54958
     */
} else {
    const roots = document.querySelectorAll('[id^="range-headless-root-"]');

    let data = {};

    // recreate `rangeHeadlessData` structure from root element datasets.
    roots.forEach((root) => {
        data[root.id] = JSON.parse(root.dataset.rangeheadless);
    });

    // main root that all shortcode instances will be rendered from, using portals.
    let rootKey = Object.entries(data).find(([k, v]) =>
        v.hasOwnProperty('retailer')
    )[0];

    /**
     * React 18+
     */
    if (createRoot) {
        createRoot(`range-headless-root-${rootKey}`).render(
            <React.StrictMode>
                <Provider store={store}>
                    <App data={data} rootKey={rootKey} />
                </Provider>
            </React.StrictMode>
        );

        /**
         * Pre-React 18
         */
    } else {
        render(
            <React.StrictMode>
                <Provider store={store}>
                    <App data={data} rootKey={rootKey} />
                </Provider>
            </React.StrictMode>,
            `range-headless-root-${rootKey}`
        );
    }
}
