import { useState, useEffect, createPortal } from '@wordpress/element';
import startCase from 'lodash.startcase';
import { menuFilter } from '../../config';
import { scrollToShop } from '../../api/methods';

const FilterNavbarDropdown = ({
    title,
    filters,
    filterType,
    setSelectedFilters,
    mobile = false,
    menuViewFilter = null,
    setMenuViewFilter = null,
    setMenuView = null,
}) => {
    const [showDropdown, setShowDropdown] = useState(
        mobile ? menuViewFilter === filterType : false
    );

    const dropdownStyle = showDropdown ? {} : (mobile ? { display: 'none' } : { opacity: 0, pointerEvents: 'none' });

    useEffect(() => {
        if (mobile) {
            setShowDropdown(menuViewFilter === filterType);
        }
    }, [menuViewFilter]);

    const [domReady, setDomReady] = useState(false);

    useEffect(() => {
        setDomReady(true);
    }, []);

    return (
        <div
            className={`headless-navbar-dropdown ${title.toLowerCase()}-dropdown ${
                mobile ? 'headless-navbar-dropdown--mobile' : ''
            }`}
            onMouseEnter={() => {
                if (!mobile) {
                    setShowDropdown(true);
                }
            }}
            onMouseLeave={() => {
                if (!mobile) {
                    setShowDropdown(false);
                }
            }}
        >
            <button
                onClick={() => {
                    if (mobile) {
                        setMenuViewFilter(filterType);
                    }
                }}
                className={`filter-navbar-dropdown-btn ${
                    mobile && menuViewFilter === filterType
                        ? 'headless-mobile-dropdown-active'
                        : ''
                }`}
            >
                {title}
            </button>
            {mobile && domReady ? (
                createPortal(
                    <ul
                        style={dropdownStyle}
                    >
                        {filters.map(
                            (filter, i) =>
                                filter !== 'NOT_APPLICABLE' &&
                                filter !== 'DEFAULT' && (
                                    <li
                                        key={i}
                                        style={{
                                            listStyle: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setSelectedFilters({
                                                ...menuFilter,
                                                [filterType]:
                                                    filter?.id || filter,
                                            });
                                            setMenuView(false);
                                            scrollToShop();
                                        }}
                                    >
                                        {filter?.name
                                            ? filter.name
                                            : startCase(filter.toLowerCase())}
                                    </li>
                                )
                        )}
                    </ul>,
                    document.getElementById('filter-nav-menuview-portal')
                )
            ) : (
                <ul style={showDropdown ? {} : { opacity: 0, pointerEvents: 'none' }}>
                    {filters.map(
                        (filter, i) =>
                            filter !== 'NOT_APPLICABLE' &&
                            filter !== 'DEFAULT' && (
                                <li
                                    key={i}
                                    style={{
                                        listStyle: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setSelectedFilters({
                                            ...menuFilter,
                                            [filterType]: filter?.id || filter,
                                        });
                                        scrollToShop();
                                    }}
                                >
                                    {filter?.name
                                        ? filter.name
                                        : startCase(filter.toLowerCase())}
                                </li>
                            )
                    )}
                </ul>
            )}
        </div>
    );
};

export default FilterNavbarDropdown;
