import startCase from 'lodash.startcase';
import { filterTypes } from '../../config';

const FilterModuleSelect = ({
    filterType,
    allValue,
    filters,
    handleChange,
    selectedFilters,
}) => {
    return (
        <select
            name={filterType}
            id={`headless_${filterType}_mobile`}
            className={`headless-dropdown headless_${filterType}_mobile headless_filter_select`}
            onChange={(e) => handleChange(e.target, filterType)}
            value={
                filterType === filterTypes.category &&
                selectedFilters[filterTypes.specials]
                    ? filterTypes.specials
                    : filterType === filterTypes.category &&
                      selectedFilters[filterTypes.staff_picks]
                    ? filterTypes.staff_picks
                    : selectedFilters[filterType]
            }
        >
            <option value={''}>{allValue}</option>
            {filters.map(
                (filter, i) =>
                    filter !== 'NOT_APPLICABLE' &&
                    filter !== 'DEFAULT' && (
                        <option key={i} value={filter?.id || filter}>
                            {filterType === filterTypes.weights
                                ? filter
                                : startCase(
                                      filter?.name
                                          ? filter.name.toLowerCase()
                                          : filter.toLowerCase()
                                  )}
                        </option>
                    )
            )}
        </select>
    );
};

export default FilterModuleSelect;
