import LazyImage from '../LazyImage';
import { formattedPrice } from '../Cart/util';
import StrainIcon from './StrainIcon';
import VariantSelect from './VariantSelect';

const ProductCard = ({
    product,
    specialProduct,
    singleProductUrl,
    selectedVariant,
    setSelectedVariant,
    styleOptions,
    handleAddItem,
    pricingType,
    errors,
}) => {
    return (
        <div className='headless-product-card' data-product-id={product.id}>
        {errors.length > 0 &&
                errors.map((error, i) => (
                    <div className='headless-error-message' key={i}>
                        <code>{error}</code>
                    </div>
                ))}
            {specialProduct === true || selectedVariant[pricingType.special] ? (
                <div className='headless-product-special'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        height='1em'
                        viewBox='0 0 512 512'
                    >
                        <path d='M204.1 32c12.7 0 24.9 5.1 33.9 14.1L410.7 218.7c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0L14.1 270.1c-9-9-14.1-21.2-14.1-33.9V80C0 53.5 21.5 32 48 32H204.1zM36.7 247.4L209.4 420.1c12.5 12.5 32.8 12.5 45.3 0L388.1 286.6c12.5-12.5 12.5-32.8 0-45.3L215.4 68.7c-3-3-7.1-4.7-11.3-4.7H48c-8.8 0-16 7.2-16 16V236.1c0 4.2 1.7 8.3 4.7 11.3zM308.4 36.9c6.1-6.4 16.2-6.6 22.6-.5l141.3 135c52.8 50.4 52.8 134.7 0 185.1l-124.6 119c-6.4 6.1-16.5 5.9-22.6-.5s-5.9-16.5 .5-22.6l124.6-119c39.6-37.8 39.6-101 0-138.8L308.9 59.6c-6.4-6.1-6.6-16.2-.5-22.6zM104 112a24 24 0 1 1 0 48 24 24 0 1 1 0-48z' />
                    </svg>
                    <span>Special offer</span>
                </div>
            ) : null}

            <LazyImage
                onclick={() => (window.location.href = singleProductUrl)}
                classnames={'headless-product-image'}
                styles={{
                    backgroundImage: `url(${product.images[0].url}?w=250)`,
                    backgroundSize: 'auto 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
            />

            {product.brand ? (
                <div className='headless-product-brands'>
                    {product.brand.name}
                </div>
            ) : (
                <div className='headless-product-brands'>
                    <span style={{ opacity: 0, visibility: 'hidden' }}>
                        NO BRAND
                    </span>
                </div>
            )}

            <h3 className='headless-product-title'>
                <a href={singleProductUrl}>{product.name}</a>
            </h3>

            <div className='headless-product-bottom'>
                <hr className='headless-hr' />

                <div className='headless-product-categories-wrapper'>
                    <div className='headless-product-categories'>
                        {product.category}
                    </div>
                    {product.potencyThc.formatted ? (
                        <div className='headless-product-card-potency'>
                            THC {product.potencyThc.formatted}
                        </div>
                    ) : null}
                    {product.strainType ? (
                        <div className='headless-product-strain'>
                            <StrainIcon strainType={product.strainType} />
                        </div>
                    ) : null}
                </div>

                <hr className='headless-hr' />

                {product.variants.length > 1 && (
                    <VariantSelect
                        product={product}
                        setSelectedVariant={setSelectedVariant}
                    />
                )}

                <div className='headless-add-to-cart'>
                    <button
                        className={`${
                            styleOptions.buttonClass
                                ? styleOptions.buttonClass + ' btn-sm'
                                : 'headless-button headless-cart-button'
                        }`}
                        onClick={handleAddItem}
                    >
                        {selectedVariant[pricingType.special] &&
                        selectedVariant[pricingType.special] <
                            selectedVariant[pricingType.standard] ? (
                            <span>
                                {formattedPrice(
                                    selectedVariant[pricingType.special]
                                )}
                            </span>
                        ) : (
                            <span>
                                {formattedPrice(
                                    selectedVariant[pricingType.standard]
                                )}
                            </span>
                        )}
                        <span className='headless-card-button-price'>|</span>
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
