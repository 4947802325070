import CategoryCards from './CategoryCards';

const CategoriesGrid = ({ images, menuUrl, atts, categories, root }) => {
    return (
        <div className='headless-menu-builder-section'>
            <div className='headless-menu-builder-title'>
                <div className='headless-shortcode-title-wrapper'>
                    <div className='headless-shortcode-title'>
                        {atts.title ? <span>{atts.title}</span> : null}
                    </div>
                </div>
            </div>
            <CategoryCards
                slider={atts.slider}
                categories={categories}
                images={images}
                menuUrl={menuUrl}
                root={root}
            />
        </div>
    );
};

export default CategoriesGrid;
