import { useState, useEffect } from '@wordpress/element';
import Product from './Product';
import { getProduct } from '../../api/methods'

const Products = ({
    products,
    display = null,
    styleOptions,
    selectedFilters = null,
    surfside,
    retailerId
}) => {
    let specialProduct = false;
    const [surfsideProduct, setSurfsideProduct] = useState(null);

    if (selectedFilters) {
        specialProduct = selectedFilters.specials === true;
    }

    useEffect(() => {
        if (surfside.enable && surfside.inline_products) {
            (async () => {
                const productData = await getProduct(retailerId, '64df77e9cc376100019a02f4');
                setSurfsideProduct(productData);
            })();
        }
    }, [retailerId, surfside.enable, surfside.inline_products]);

    return (
        <>
            {products.length === 0 ? (
                <p className='headless-no-products'>
                    {selectedFilters && selectedFilters.search.length > 0
                        ? 'Sorry, no products were found! Please try refining your search, or use the filter options on the sidebar to narrow down the products more.'
                        : 'Sorry! No products found!'}
                </p>
            ) : (
                <>
                    {surfside.enable && surfside.inline_products && surfsideProduct &&
                        <Product
                            key='99999'
                            product={surfsideProduct}
                            display={display}
                            styleOptions={styleOptions}
                            specialProduct={specialProduct}
                            surfside={surfside}
                            surfsideProduct={true}
                        />
                    }
                    {products.map((product, i) => (
                        <Product
                            key={i}
                            product={product}
                            display={display}
                            styleOptions={styleOptions}
                            specialProduct={specialProduct}
                            surfside={surfside}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default Products;
