import MenuBanner from './MenuBanner';
import MenuOverlay from './MenuOverlay';
import ProductBanner from './ProductBanner';
import CartBanner from './CartBanner';
import CheckoutBanner from './CheckoutBanner';
import { useSelector } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';

const Surfside = ({
    accountId,
    sourceId,
    placement,
    placementId,
    category,
    slider
}) => {
    const retailerId = useSelector(selectRetailerId)

    switch (placement) {
        case 'menu-banner':
            return (
                <MenuBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                    slider={slider}
                />
            );
        case 'menu-overlay':
            return (
                <MenuOverlay
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'category-banner':
            return (
                <MenuBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    category={category}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'product':
            return (
                <ProductBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'cart':
            return (
                <CartBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'checkout':
            return (
                <CheckoutBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        default:
            return null;
    }
};

export default Surfside;
