import { useSelector } from 'react-redux';
import { selectRetailerData } from '../../store/retailer/retailerSlice';
import { isOpen, today } from '../../util';

const OpenOrClosed = () => {
    const retailerData = useSelector(selectRetailerData);

    const { curbsidePickup, delivery, driveThruPickup, pickup } =
        retailerData.fulfillmentOptions;

    return (
        <div className='headless-openorclosed-wrapper'>
            {(curbsidePickup || driveThruPickup || pickup) && (
                <div className='headless-oc-option'>
                    {isOpen(retailerData.hours.pickup[today()]) ? (
                        <span className='headless-oc-open'>OPEN</span>
                    ) : (
                        <span className='headless-oc-closed'>CLOSED</span>
                    )}
                    <span className='headless-oc-name'>Pickup</span>
                </div>
            )}
            {delivery && (
                <div className='headless-oc-option'>
                    {isOpen(retailerData.hours.delivery[today()]) ? (
                        <span className='headless-oc-open'>OPEN</span>
                    ) : (
                        <span className='headless-oc-closed'>CLOSED</span>
                    )}
                    <span className='headless-oc-name'>Delivery</span>
                </div>
            )}
        </div>
    );
};

export default OpenOrClosed;
