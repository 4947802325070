
const StrainIcon = ({ strainType }) => {
    switch (strainType) {
        case 'HYBRID':
            return (
                <span className='headless-strain-icon strain-hybrid'>
                    Hybrid
                </span>
            );
        case 'INDICA':
            return (
                <span className='headless-strain-icon strain-indica'>
                    Indica
                </span>
            );
        case 'SATIVA':
            return (
                <span className='headless-strain-icon strain-sativa'>
                    Sativa
                </span>
            );
        case 'HIGH_CBD':
            return <span className='headless-strain-icon strain-cbd'>CBD</span>;
        default:
            return null;
    }
};

export default StrainIcon;
