import { ucwords } from '../../util';

const CategoryCard = ({ category, image, menuUrl }) => {
    return (
        <div
            onClick={() => {
                window.location.href = menuUrl + '?category=' + category;
            }}
            className='headless-category-card'
        >
            {image ? (
                <img
                    src={`${window.location.origin}${image.sizes.thumbnail}`}
                ></img>
            ) : (
                <div className='default-category-image'></div>
            )}

            <div className='headless-product-title'>
                {category === 'PRE_ROLLS'
                    ? 'Pre-Rolls'
                    : category === 'CBD'
                    ? 'CBD'
                    : ucwords(category)}
            </div>
        </div>
    );
};

export default CategoryCard;
