import { useRef, useState, useEffect } from '@wordpress/element';
import { menuFilter } from '../../config';

const SearchBar = ({ selectedFilters, setSelectedFilters }) => {
    const [searchText, setSearchText] = useState('');
    const prevSearchText = useRef({ text: searchText }).current;

    const inputRef = useRef(null);

    useEffect(() => {
        if (
            searchText.length > 0 ||
            (searchText.length === 0 && prevSearchText.text.length === 1)
        ) {
            setSelectedFilters({ ...menuFilter, search: searchText });
        }

        return () => {
            prevSearchText.text = searchText;
        };
    }, [searchText, setSelectedFilters]);

    const clearSearchInput = () => {
        setSelectedFilters({ ...selectedFilters, search: '' });
        setSearchText('');
        inputRef.current.focus();
    };

    return (
        <div className='headless-searchbar'>
            <input
                ref={inputRef}
                placeholder='Search...'
                className='headless-nav-searchbar-input'
                type='text'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText && (
                <div className="clear-search-icon" onMouseDown={(e) => { e.preventDefault(); clearSearchInput(); }}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm97.9-320l-17 17-47 47 47 47 17 17L320 353.9l-17-17-47-47-47 47-17 17L158.1 320l17-17 47-47-47-47-17-17L192 158.1l17 17 47 47 47-47 17-17L353.9 192z'/>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default SearchBar;
