import LazyImage from '../LazyImage';
import WithBlaze from '../Products/WithBlaze';
import Surfside from '../Surfside/Surfside';
import { blazeConfigMenuBanners, menuFilter } from '../../config';

const MenuBanners = ({
    banners,
    surfside,
    surfsideBanner,
    rootId,
    setSelectedFilters,
}) => {
    console.log(surfside);
    console.log(surfsideBanner);
    return (
        <div className='headless-menu-banners'>
            <div
                className='headless-banner-slider headless-loop-slider blaze-slider'
                data-blaze={JSON.stringify(blazeConfigMenuBanners())}
            >
                <WithBlaze root={rootId}>
                    {banners.map((banner, i) => {
                        const {
                            headless_location_banner_link_type: linkType,
                            headless_location_menu_banner_product_link:
                                productLink,
                            headless_location_menu_banner_custom_link:
                                customLink,
                            headless_location_menu_banner_brand_link: brandLink,
                            headless_location_menu_banner_category_link:
                                categoryLink,
                        } = banner;

                        let linkDestination,
                            linkFilter,
                            linkName = '';

                        switch (linkType) {
                            case 'brand':
                                linkFilter = brandLink;
                                linkName = 'brandId';
                                break;
                            case 'category':
                                linkFilter = categoryLink;
                                linkName = 'category';
                                break;
                            case 'product':
                                linkDestination = productLink;
                                break;
                            case 'custom':
                                linkDestination = customLink;
                                break;
                            default:
                                break;
                        }

                        return (
                            <div
                                className='headless-menu-banner-single'
                                role='button'
                                key={i}
                                onClick={() => {
                                    if (linkDestination) {
                                        window.open(linkDestination, '_blank');
                                    } else if (linkFilter) {
                                        setSelectedFilters({
                                            ...menuFilter,
                                            [linkName]: linkFilter,
                                        });
                                        const shopDiv =
                                            document.getElementById('shop');
                                        const navBar =
                                            document.getElementById(
                                                'topLevelNav'
                                            );
                                        if (shopDiv && navBar) {
                                            const shopDivTop =
                                                shopDiv.getBoundingClientRect()
                                                    .top + window.scrollY;
                                            const navHeight =
                                                navBar.offsetHeight + 40;
                                            const scrollToPosition =
                                                shopDivTop - navHeight;

                                            window.scrollTo({
                                                top: scrollToPosition,
                                                behavior: 'smooth',
                                            });
                                        } else if (shopDiv) {
                                            shopDiv.scrollIntoView({
                                                behavior: 'smooth',
                                            });
                                        }
                                    }
                                }}
                            >
                                <LazyImage
                                    src={
                                        banner
                                            .headless_location_banner_image_field
                                            .url
                                    }
                                    alt='menu banner image'
                                />
                            </div>
                        );
                    })}
                    {surfside.enable && surfsideBanner && (
                        <Surfside
                            placement='menu-banner'
                            accountId={surfside.account_id}
                            sourceId={surfside.source_id}
                            placementId={surfside.placement_ids.menu_banner}
                            slider='true'
                        />
                    )}
                </WithBlaze>
            </div>
        </div>
    );
};

export default MenuBanners;
