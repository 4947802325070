import { useState, useEffect } from '@wordpress/element';
import Products from '../components/Products/Products';
import Specials from '../components/Products/Specials';
import StoreBanner from '../components/Menu/StoreBanner';
import MenuBanners from '../components/Menu/MenuBanners';
import Filter from '../components/Filter/Filter';
import { defaultSort, menuFilter } from '../config';
import Surfside from '../components/Surfside/Surfside';
import {
    getProducts,
    getSpecialsMenu,
    getStaffPicksMenu,
} from '../api/methods';
import LoadingSpinner from '../components/LoadingSpinner';
import ShowMore from '../components/Menu/ShowMore';
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerId } from '../store/retailer/retailerSlice';
import FilterNavbar from '../components/Filter/FilterNavbar';
import ProductNav from '../components/Menu/ProductNav';
import {
    selectSelectedSpecialId,
    selectSpecialsList,
    selectSpecialsListStatus,
    fetchSpecialsList,
} from '../store/specials/specialsSlice';
import { getInitialMenuFilter } from '../util';

const Menu = ({ data }) => {
    const dispatch = useDispatch();
    const retailerId = useSelector(selectRetailerId);
    const pricingType = useSelector((state) => state.cart.data.pricingType);

    const { surfside, shopEditor } = data;
    const { menuConfig } = shopEditor;

    const retailerConfig = data.retailerConfig.retailers[retailerId];
    const filterTerms = {
        ...data.filterTerms,
        brands: data.filterTerms.brands[retailerId],
    };
    const paginationLimit = Number(menuConfig.paginationLimit);
    const numColumns = Number(menuConfig.numColumns);

    const [selectedFilters, setSelectedFilters] = useState(
        getInitialMenuFilter(menuFilter, filterTerms)
    );
    const [productsToDisplay, setProductsToDisplay] = useState(null);

    const specialsList = useSelector(selectSpecialsList);
    const specialsListStatus = useSelector(selectSpecialsListStatus);
    const selectedSpecialId = useSelector(selectSelectedSpecialId);

    const [displaySpecialsList, setDisplaySpecialsList] = useState(false);
    const [selectedSpecial, setSelectedSpecial] = useState(null);

    const [paginationOffset, setPaginationOffset] = useState(1);
    const [sort, setSort] = useState(defaultSort);

    /**
     * Set specials.
     * Runs only on initial pageload.
     */
    useEffect(() => {
        if (specialsListStatus === 'idle') {
            dispatch(fetchSpecialsList(retailerId));
        }
    }, [specialsListStatus, dispatch]);

    /**
     * Updates products to display when filters are updated, pricingType, sort, or selected special is updated.
     * Also runs on initial page load.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetProducts = async () => {
            const response = await getProducts(
                retailerId,
                0,
                paginationLimit,
                selectedFilters,
                pricingType,
                sort,
                signal
            );
            setProductsToDisplay(response);
        };

        const getAndSetSpecials = async () => {
            if (selectedSpecialId) {
                setSelectedSpecial(
                    specialsList.find(
                        (special) => special.id === selectedSpecialId
                    )
                );
            } else {
                setSelectedSpecial(null);
            }

            setProductsToDisplay(
                await getSpecialsMenu(
                    retailerId,
                    0,
                    paginationLimit,
                    selectedSpecialId ||
                        specialsList.map((specials) => specials.id),
                    pricingType,
                    sort,
                    signal
                )
            );
        };

        const getAndSetStaffPicks = async () => {
            setProductsToDisplay(
                await getStaffPicksMenu(
                    retailerId,
                    0,
                    paginationLimit,
                    'STAFF_PICKS',
                    pricingType,
                    sort,
                    signal
                )
            );
        };

        if (selectedFilters.staff_picks) {
            getAndSetStaffPicks();
            setDisplaySpecialsList(false);
        } else if (selectedFilters.specials && specialsList) {
            setDisplaySpecialsList(true);
            getAndSetSpecials();
        } else {
            getAndSetProducts();
            setDisplaySpecialsList(false);
        }

        setPaginationOffset(1);

        return () => controller.abort();
    }, [selectedFilters, pricingType, sort, selectedSpecialId, specialsList]);

    // console.log({
    //     productsToDisplay,
    //     paginationOffset,
    //     selectedFilters,
    //     displaySpecialsList,
    //     selectedSpecial,
    //     specialsList,
    //     retailerConfig
    // });

    return (
        <>
            {menuConfig.showFilterNavbar && (
                <FilterNavbar
                    filterTerms={filterTerms}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    styleOptions={shopEditor?.customStyleOptions}
                />
            )}
            {surfside.enable &&
            surfside.showMenuOverlay &&
            !retailerConfig.showMenuBannerSurfside ? (
                <Surfside
                    placement='menu-overlay'
                    accountId={surfside.account_id}
                    sourceId={surfside.source_id}
                    placementId={surfside.placement_ids.menu_overlay}
                />
            ) : null}
            {surfside.enable &&
            surfside.showMenuBanner &&
            !retailerConfig.showMenuBannerSurfside ? (
                <Surfside
                    placement='menu-banner'
                    accountId={surfside.account_id}
                    sourceId={surfside.source_id}
                    placementId={surfside.placement_ids.menu_banner}
                />
            ) : null}
            {retailerConfig.showMenuBanner ? (
                <MenuBanners
                    banners={retailerConfig.menuBanners}
                    rootId={data.rootId}
                    setSelectedFilters={setSelectedFilters}
                    surfsideBanner={retailerConfig.showMenuBannerSurfside}
                    surfside={surfside}
                />
            ) : null}
            {menuConfig.showFilterSidebar && retailerConfig.storeBanner && (
                <StoreBanner />
            )}
            <div className={'headless-shop-container headless-menu'} id='shop'>
                {menuConfig.showFilterSidebar ? (
                    <Filter
                        filterTerms={filterTerms}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        styleOptions={shopEditor?.customStyleOptions}
                        navbar={menuConfig.showFilterNavbar}
                    />
                ) : null}
                <div
                    className={`headless-products-container ${
                        menuConfig.showFilterSidebar
                            ? 'has-filter'
                            : 'no-filter'
                    }`}
                >
                    {menuConfig.showProductNavbar ? (
                        <ProductNav
                            selectedFilters={selectedFilters}
                            sort={sort}
                            setSort={setSort}
                        />
                    ) : null}

                    {specialsList && displaySpecialsList && (
                        <Specials selectedSpecial={selectedSpecial} />
                    )}
                    <div
                        className={`${
                            menuConfig.productDisplayStyle === 'list'
                                ? 'headless-products-list'
                                : `headless-products-row headless-columns-${numColumns}`
                        } `}
                    >
                        {productsToDisplay ? (
                            <Products
                                products={productsToDisplay}
                                numResults={paginationLimit}
                                display={menuConfig.productDisplayStyle}
                                styleOptions={shopEditor?.customStyleOptions}
                                selectedFilters={selectedFilters}
                                surfside={surfside}
                                retailerId={retailerId}
                            />
                        ) : (
                            <LoadingSpinner loadingType='list' />
                        )}
                    </div>
                    {productsToDisplay && (
                        <ShowMore
                            productsToDisplay={productsToDisplay}
                            setProductsToDisplay={setProductsToDisplay}
                            paginationOffset={paginationOffset}
                            setPaginationOffset={setPaginationOffset}
                            paginationLimit={paginationLimit}
                            selectedFilters={selectedFilters}
                            selectedSpecial={selectedSpecial}
                            sort={sort}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Menu;
