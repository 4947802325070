import WithBlaze from '../Products/WithBlaze';
import CategoryCard from './CategoryCard';
import { blazeConfigCategories } from '../../config';

const CategoryCards = ({ categories, images, menuUrl, root, slider }) => {
    return (
        <div
            className={`headless-products-container ${
                slider
                    ? 'headless-slider headless-loop-slider blaze-slider no-filter'
                    : ''
            }`}
            data-blaze={slider ? JSON.stringify(blazeConfigCategories()) : null}
        >
            {slider ? (
                <WithBlaze root={root}>
                    {categories.map((cat, i) => (
                        <CategoryCard
                            key={i}
                            category={cat}
                            image={images[cat]}
                            menuUrl={menuUrl}
                        />
                    ))}
                </WithBlaze>
            ) : (
                <>
                    {categories.map((cat, i) => (
                        <CategoryCard
                            key={i}
                            category={cat}
                            image={images[cat]}
                            menuUrl={menuUrl}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default CategoryCards;
