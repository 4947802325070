import WithBlaze from '../Products/WithBlaze';
import { blazeConfigSpecials } from '../../config';
import SpecialCard from './SpecialCard';
import { useSelector } from 'react-redux';
import { selectSpecialsList } from '../../store/specials/specialsSlice';

const SpecialCards = ({ atts, menuUrl, root }) => {
    const specials = useSelector(selectSpecialsList);

    return (
        <div
            className={`headless-products-container ${
                atts.slider
                    ? 'headless-slider headless-loop-slider blaze-slider no-filter'
                    : ''
            }`}
            data-blaze={
                atts.slider
                    ? JSON.stringify(blazeConfigSpecials(atts.columns))
                    : null
            }
        >
            {atts.slider ? (
                <WithBlaze root={root}>
                    {specials.map((special, i) => (
                        <SpecialCard
                            key={i}
                            special={special}
                            menuUrl={menuUrl}
                        />
                    ))}
                </WithBlaze>
            ) : (
                <>
                    {specials.map((special, i) => (
                        <SpecialCard
                            key={i}
                            special={special}
                            menuUrl={menuUrl}
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default SpecialCards;
