import SingleProduct from './views/SingleProduct';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import ShopNav from './views/ShopNav';
import ProductsView from './views/ProductsView';
import Menu from './views/Menu';

const Shortcode = ({ dataset }) => {
    let { shortcode } = dataset;

    console.log({ shortcode, dataset });

    switch (shortcode) {
        case 'headlessmenu':
            return <Menu data={dataset} />;
        case 'headlessproducts':
            return <ProductsView data={dataset} />;
        case 'singleproduct':
            return <SingleProduct data={dataset} />;
        case 'headlesscart':
            return <Cart data={dataset} />;
        case 'headlesscheckout':
            return <Checkout data={dataset} />;
        case 'shopnav':
            return <ShopNav data={dataset} />;
        default:
            return <h1>Shortcode error!</h1>;
    }
};

export default Shortcode;
