import LazyImage from '../LazyImage';

const SpecialCard = ({ special, menuUrl }) => {
    return (
        <LazyImage
            classnames='headless-specials-card'
            styles={{
                backgroundImage: `url(${
                    special.menuDisplayConfiguration.image !=
                    '/images/default-special-card.jpg'
                        ? special.menuDisplayConfiguration.image
                        : 'https://range-headless.s3.amazonaws.com/specials-bg.jpg'
                })`,
            }}
            onclick={() => {
                window.location.href = menuUrl + '?special=' + special.id;
            }}
        >
            <div className='headless-specials-card-overlay'></div>
            <div className='headless-specials-title'>{special.name}</div>
            <button className='headless-specials-button'>Shop Offer</button>
        </LazyImage>
    );
};

export default SpecialCard;
