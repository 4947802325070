import { useState, useEffect } from '@wordpress/element';
import { addItem, updateQuantity } from '../../api/methods';
import Variant from './Variant';
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';
import {
    openSidebar,
    updateCart,
    selectCartData,
} from '../../store/cart/cartSlice';

const AddToCart = ({ variants, styleOptions }) => {
    const [errors, setErrors] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState(variants[0].id);
    const [quantityOption, setQuantityOption] = useState(10);
    const [priceOption, setPriceOption] = useState(variants[0].priceRec);

    const dispatch = useDispatch();
    const retailerId = useSelector(selectRetailerId);
    const cartData = useSelector(selectCartData);

    useEffect(() => {
        setQuantityOption(
            variants.find((variant) => variant.id === selectedVariant).quantity
        );
        setPriceOption(
            variants
                .find((variant) => variant.id === selectedVariant)
                .priceRec.toFixed(2)
        );
    }, [selectedVariant]);

    return (
        <>
            <div className='headless-sp-variations'>
                {variants.map((variant, i) => (
                    <Variant
                        variant={variant}
                        key={i}
                        i={i}
                        selectedVariant={selectedVariant}
                        setSelectedVariant={setSelectedVariant}
                    />
                ))}
            </div>
            <div className='headless-sp-variation-price'>
                {`$${priceOption}`}
            </div>
            <div className='headless-sp-add-cart'>
                <div className='headless-dropdown-quantity'>
                    <select
                        className='headless-dropdown headless-dropdown-quantity-select light'
                        placeholder='Select a quantity'
                        onChange={(e) => setQuantity(Number(e.target.value))}
                    >
                        {[...Array(quantityOption)].map((_, i) => (
                            <option value={i + 1} key={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </select>
                </div>
                <div
                    className='headless-add-to-cart'
                    onClick={async () => {
                        const productId = selectedVariant.split('~')[0];
                        const option = selectedVariant.split('~')[1];

                        const existingItem = cartData.items.find(
                            (item) =>
                                item.productId === productId &&
                                item.option === option
                        );

                        let response;
                        if (existingItem) {
                            response = await updateQuantity(
                                retailerId,
                                cartData.id,
                                existingItem.id,
                                existingItem.quantity + quantity
                            );
                        } else {
                            response = await addItem(
                                retailerId,
                                cartData.id,
                                quantity,
                                selectedVariant
                            );
                        }

                        if (response.hasOwnProperty('errors')) {
                            setErrors(
                                response.errors.map((error) => {
                                    if (error?.extensions?.errors) {
                                        return error.extensions.errors.map(
                                            (extError) => extError.detail
                                        );
                                    } else {
                                        return error.message;
                                    }
                                })
                            );
                        } else {
                            dispatch(updateCart(response));
                            dispatch(openSidebar());
                            setErrors([]);
                        }
                    }}
                >
                    <button
                        className={`${
                            styleOptions.buttonClass
                                ? styleOptions.buttonClass
                                : 'headless-button headless-button-lg headless-cart-button'
                        }`}
                    >
                        <span>Add to Cart</span>
                    </button>
                    {errors.length > 0 &&
                        errors.map((error, i) => (
                            <small key={i}>
                                <code>{error}</code>
                            </small>
                        ))}
                </div>
            </div>
        </>
    );
};

export default AddToCart;
