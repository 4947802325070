import { useState, useEffect } from '@wordpress/element';
import Products from '../Products/Products';
import WithBlaze from '../Products/WithBlaze';
import { blazeConfig, defaultSort } from '../../config';
import Surfside from '../Surfside/Surfside';
import { useSelector } from 'react-redux';
import { selectRetailerId } from '../../store/retailer/retailerSlice';
import { getProducts } from '../../api/methods';
import LoadingSpinner from '../LoadingSpinner';

const ProductsGrid = ({ filter, root, styleOptions, atts, surfside }) => {
    const retailerId = useSelector(selectRetailerId);
    const pricingType = useSelector((state) => state.cart.data.pricingType);

    const [products, setProducts] = useState(null);
    const paginationLimit = atts.numProducts === -1 ? 20 : atts.numProducts;

    /**
     * Runs on initial slider load.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetProducts = async () => {
            setProducts(
                await getProducts(
                    retailerId,
                    0,
                    paginationLimit,
                    filter,
                    pricingType,
                    defaultSort,
                    signal
                )
            );
        };

        getAndSetProducts();

        return () => controller.abort();
    }, []);
    return (
        <>
            <div className='headless-shortcode-title-wrapper'>
                <div className='headless-shortcode-title'>
                    {atts.title ? <span>{atts.title}</span> : null}
                </div>
                <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => null}
                    className={`${
                        styleOptions.buttonClass
                            ? styleOptions.buttonClass +
                              ' btn-sm headless-title-button'
                            : 'headless-button headless-button-sm headless-title-button'
                    }`}
                >
                    Shop all {atts.title}
                </a>
            </div>
            {surfside.enable &&
                surfside.showCategoryBanner ? (
                    <Surfside
                        placement='category-banner'
                        accountId={surfside.account_id}
                        sourceId={surfside.source_id}
                        placementId={surfside.placement_ids.menu_banner}
                        category={atts.menuFilter.category}
                    />
                ) : null}
            {products ? (
                <div
                    className={`headless-products-container ${
                        atts.slider
                            ? 'headless-slider headless-loop-slider blaze-slider no-filter'
                            : ''
                    }`}
                    data-blaze={
                        atts.slider
                            ? JSON.stringify(blazeConfig(atts.columns))
                            : null
                    }
                >
                    {atts.slider ? (
                        <WithBlaze root={root}>
                            <Products
                                products={products}
                                styleOptions={styleOptions}
                                surfside={surfside}
                            />
                        </WithBlaze>
                    ) : (
                        <Products
                            products={products}
                            styleOptions={styleOptions}
                            surfside={surfside}
                        />
                    )}
                </div>
            ) : (
                <LoadingSpinner loadingType='slider' />
            )}
        </>
    );
};

export default ProductsGrid;
