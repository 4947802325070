
import FilterModuleSelect from './FilterModuleSelect';
import FilterModuleRange from './FilterModuleRange';
import FilterModuleCheckbox from './FilterModuleCheckbox';
import { filterTypes, menuFilter } from '../../config';
import CaretToggle from './CaretToggle';

const FilterMobile = ({
    selectedFilters,
    setSelectedFilters,
    filterTerms,
    handleChange,
    handleRangeChange,
    styleOptions,
}) => {
    const { categories, subcategories, strains, brands, effects, weights } =
        filterTerms;

    return (
        <div className='headless-filter-mobile'>
            <CaretToggle title={'Filter Products'}>
                <div className='filter-sidebar-options'>
                    <FilterModuleSelect
                        allValue={'All Categories'}
                        filterType={filterTypes.category}
                        filters={categories}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                    <FilterModuleSelect
                        allValue={'All Subcategories'}
                        filterType={filterTypes.subcategory}
                        filters={subcategories}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                    <FilterModuleSelect
                        allValue={'All Strains'}
                        filterType={filterTypes.strain}
                        filters={strains}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                    <FilterModuleCheckbox
                        filterType={filterTypes.effects}
                        filters={effects}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                        mobile={true}
                        title={'Effects'}
                    />
                    <FilterModuleCheckbox
                        filterType={filterTypes.weights}
                        filters={weights}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                        mobile={true}
                        title={'Weights'}
                    />
                    <FilterModuleSelect
                        allValue={'All Brands'}
                        filterType={filterTypes.brand}
                        filters={brands}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        handleChange={handleChange}
                    />
                    <FilterModuleRange
                        title={'THC Potency'}
                        filterType={filterTypes.potencyThc}
                        selectedFilters={selectedFilters}
                        handleChange={handleRangeChange}
                        mobile={true}
                    />
                    <FilterModuleRange
                        title={'CBD Potency'}
                        filterType={filterTypes.potencyCbd}
                        selectedFilters={selectedFilters}
                        handleChange={handleRangeChange}
                        mobile={true}
                    />
                    <button
                        id='clear-dropdown-filters'
                        className={`${
                            styleOptions.buttonClass
                                ? styleOptions.buttonClass + ' btn-sm'
                                : ''
                        }`}
                        onClick={() => {
                            setSelectedFilters(menuFilter);
                        }}
                    >
                        Clear Filters
                    </button>
                </div>
            </CaretToggle>
        </div>
    );
};

export default FilterMobile;
