import { today } from '../../util';

const TodaysHours = ({ hours }) => {
    return (
        <p className='todays-hours'>
            {hours[today()].active
                ? `${hours[today()].start} - ${hours[today()].end}`
                : 'Not Available'}
        </p>
    );
};

export default TodaysHours;
