import CartIcon from '../CartIcon';
import { totalItemsInCart } from '../Cart/util';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartData } from '../../store/cart/cartSlice';

const FilterNavbarCartIcon = () => {
    const dispatch = useDispatch();
    const cartData = useSelector(selectCartData);

    return (
        <div
            className='headless-nav-cart-icon'
            style={{ position: 'static', cursor: 'pointer' }}
            onClick={() => location.href='/cart'}
        >
            <CartIcon />
            <span className='headless-nav-cart-quantity'>{totalItemsInCart(cartData)}</span>
        </div>
    );
};

export default FilterNavbarCartIcon;
