import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cart/cartSlice';
import retailerReducer from './retailer/retailerSlice';
import specialsReducer from './specials/specialsSlice';

export default configureStore({
    reducer: {
        cart: cartReducer,
        retailer: retailerReducer,
        specials: specialsReducer,
    },
});
