const VariantSelect = ({ product, setSelectedVariant }) => (
    <select
        className='headless-dropdown light'
        placeholder='Select an option'
        onChange={(e) => setSelectedVariant(JSON.parse(e.target.value))}
    >
        {product.variants.map((variant, i) => (
            <option key={i} value={JSON.stringify(variant)}>
                {variant.option}
            </option>
        ))}
    </select>
);

export default VariantSelect;
