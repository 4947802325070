

const Category = ({ category, strainType }) => {
    const StrainIcon = () => {
        switch (strainType) {
            case 'HYBRID':
                return (
                    <span className='headless-strain-icon strain-hybrid'>
                        Hybrid
                    </span>
                );
            case 'INDICA':
                return (
                    <span className='headless-strain-icon strain-indica'>
                        Indica
                    </span>
                );
            case 'SATIVA':
                return (
                    <span className='headless-strain-icon strain-sativa'>
                        Sativa
                    </span>
                );
            case 'HIGH_CBD':
                return (
                    <span className='headless-strain-icon strain-cbd'>
                        CBD
                    </span>
                );
            default:
                return null;
        }
    };

    return (
        <div className='headless-sp-category-bar'>
            <div className='headless-sp-categories'>
                <div className='headless-list-category'>
                    {category}
                </div>
            </div>
            <div className='headless-sp-strain'>
                {strainType === 'NOT_APPLICABLE' ? null : (
                    <div className='headless-product-strain'>
                        <StrainIcon />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Category;
