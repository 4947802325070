import CheckoutBtn from './CheckoutBtn';
import PriceSummary from './PriceSummary';
import AddressInput from './AddressInput';
import OrderType from './OrderType';
import { useSelector } from 'react-redux';
import { selectCartData } from '../../store/cart/cartSlice';

const PickupOrDelivery = ({ styleOptions }) => {
    const cartData = useSelector(selectCartData);

    return (
        <div className={'headless-pickup-or-delivery'}>
            <OrderType />
            {cartData.orderType === 'DELIVERY' && <AddressInput />}
            <PriceSummary priceSummary={cartData.priceSummary} />
            <CheckoutBtn
                url={cartData.redirectUrl}
                styleOptions={styleOptions}
            />
        </div>
    );
};

export default PickupOrDelivery;
