import { useEffect } from '@wordpress/element';
import BlazeSlider from 'blaze-slider';

const WithBlaze = ({ root, children }) => {
    const loadSliders = () => {
        let headlessSliders = document.getElementById(`range-headless-root-${root}`).getElementsByClassName(
            'headless-loop-slider'
        );

        for (
            var sliderCount = 0;
            sliderCount < headlessSliders.length;
            sliderCount++
        ) {
            let blazeConfig = JSON.parse(
                headlessSliders[sliderCount].dataset.blaze
            );

            let blazeSlider = new BlazeSlider(
                headlessSliders[sliderCount],
                blazeConfig
            );

            let sliderPrev = headlessSliders[sliderCount].querySelector(
                '.headless-slider-prev'
            );
            let sliderNext = headlessSliders[sliderCount].querySelector(
                '.headless-slider-next'
            );

            if (sliderPrev && sliderNext) {
                sliderPrev.addEventListener('click', () => blazeSlider.prev());
                sliderNext.addEventListener('click', () => blazeSlider.next());
            }
        }

        // Main shop category slider.
        let headlessCategorySliders = document.getElementsByClassName(
            'headless-category-slider'
        );

        for (
            var sliderCount = 0;
            sliderCount < headlessCategorySliders.length;
            sliderCount++
        ) {
            let blazeConfig = JSON.parse(
                headlessCategorySliders[sliderCount].dataset.blaze
            );

            let blazeSlider = new BlazeSlider(
                headlessCategorySliders[sliderCount],
                blazeConfig
            );

            let sliderPrev = headlessCategorySliders[sliderCount].querySelector(
                '.headless-slider-prev'
            );

            let sliderNext = headlessCategorySliders[sliderCount].querySelector(
                '.headless-slider-next'
            );

            if (sliderPrev && sliderNext) {
                sliderPrev.addEventListener('click', () => blazeSlider.prev());
                sliderNext.addEventListener('click', () => blazeSlider.next());
            }
        }
    };

    useEffect(() => {
        if (children) {
            loadSliders();
        }
    }, []);

    return (
        <div className='headless-slider-wrap'>
            <div className='headless-slider-prev' role='button'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
                    <path d='M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z' />
                </svg>
            </div>
            <div className='headless-slider-next' role='button'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
                    <path d='M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z' />
                </svg>
            </div>
            <div className='blaze-container'>
                <div className='blaze-track-container'>
                    <div className='blaze-track'>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default WithBlaze;
