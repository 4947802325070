import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOrCreateCart } from '../../api/methods';

const initialState = {
    sidebarToggle: false,
    data: null,
    status: 'idle',
};

export const fetchOrCreateCart = createAsyncThunk(
    'retailer/fetchOrCreateCart',
    async (retailerId) => {
        const response = await getOrCreateCart(retailerId);
        return response;
    }
);

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarToggle = !state.sidebarToggle;
        },
        openSidebar: (state) => {
            state.sidebarToggle = true;
        },
        updateCart: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrCreateCart.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrCreateCart.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            });
    },
});

export const { toggleSidebar, openSidebar, updateCart } = cartSlice.actions;

export const selectSidebarToggle = (state) => state.cart.sidebarToggle;
export const selectCartData = (state) => state.cart.data;

export default cartSlice.reducer;
