import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSpecialsList } from '../../api/api';

const initialState = {
    selectedSpecialId: null,
    list: {
        data: null,
        status: 'idle',
        error: null,
    },
};

export const fetchSpecialsList = createAsyncThunk(
    'specials/fetchSpecialsList',
    async (retailerId) => {
        const response = await getSpecialsList(retailerId);
        return response;
    }
);

export const specialsSlice = createSlice({
    name: 'specials',
    initialState,
    reducers: {
        setSelectedSpecialId: (state, action) => {
            state.selectedSpecialId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpecialsList.pending, (state) => {
                state.list.status = 'loading';
            })
            .addCase(fetchSpecialsList.fulfilled, (state, action) => {
                if (action.payload.hasOwnProperty('errors')) {
                    state.list.status = 'failed';
                    state.list.data = false;
                    state.list.error = action.payload.errors;
                } else {
                    state.list.status = 'succeeded';
                    state.list.data = action.payload.data.specials;
                }
            });
    },
});

export const { setSelectedSpecialId } = specialsSlice.actions;

export const selectSpecialsList = (state) => state.specials.list.data;
export const selectSpecialsListStatus = (state) => state.specials.list.status;
export const selectSelectedSpecialId = (state) => state.specials.selectedSpecialId;

export default specialsSlice.reducer;
