/**
 * Calculates total items in cart.
 * @param {object} cart Current cart object.
 * @returns {number}
 */
export const totalItemsInCart = (cart) => {
    return cart?.items.map((item) => item.quantity).reduce((a, b) => a + b, 0);
};

/**
 * Gets and formats number into currency.
 * Product prices do not have trailing 00s but cart summary prices do.
 *
 * @param {number} num Unformatted price.
 * @param {bool} fix Whether trailing 00s are included and should be formatted with decimal point.
 *
 * @returns {string}
 */
export const formattedPrice = (num, fix = false) => {
    const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(fix ? (num / 100).toFixed(2) : num);

    if (fix) {
        return formatted;
    } else if (Number.isInteger(num)) {
        return formatted.replace('.00', '');
    } else {
        return formatted;
    }
};

/**
 * Calculate percenage off.
 *
 * @param {mixed} price
 * @param {mixed} specialPrice
 *
 * @returns {mixed}
 */
export const percentageOff = (price, specialPrice) =>
    Math.round(((price - specialPrice) / price) * 100);
